<template>
    <div class="dialog-addservice-returnmedical">
        <div class="header">
            <div class="title">
                <img src="../../../../assets/icons/myservices/return.png" alt="icone retorno medical" />
                <b>Direito a retorno</b>
            </div>
            <div class="flex align-items-center" :class="disabled && 'cursor-not-allowed'">
                <InputSwitch v-model="bool" :disabled="disabled" />
            </div>
        </div>
        <div v-if="bool" class="flex justify-content-between align-items-center mt-3">
            <label for="duration" class="txt-title">
                Número máximo de dias para o retorno:
            </label>
            <InputNumber
                id="expiry"
                placeholder="0 dia(s)"
                :style="{ width: '80px' }"
                v-model="maxDays"
                suffix=" dia(s)"
                :disabled="disabled"     
            />
            <!-- :disabled="type === 'View'" -->
        </div>
    </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";

export default {
    props: ["pb_return", "timeReturn", "disabled"],
    emits: ["update:pb_return", "update:timeReturn"],
    setup(props, { emit }) {
        const bool = ref(false);
        const maxDays = ref(0);

        
        watch( () => bool.value, (newValue, oldValue) => {
            emit("update:pb_return", newValue);
        }); 
        
        watch( () => maxDays.value, (newValue, oldValue) => {
            if(newValue){
                emit("update:timeReturn", newValue);
            }
        }); 

        onMounted(() => {
            if (props.pb_return) {
                bool.value = props.pb_return;
            }
            if(props.timeReturn){
                maxDays.value = props.timeReturn;
            }
        })

        return {
            bool,
            maxDays,
        }
    },
};
</script>


<style lang="scss" scoped>
.dialog-addservice-returnmedical {
    display: flex;
    flex-direction: column;
    // gap: 4px;
    width: 100%;
    padding: 13px 16px;
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title{
        display: flex;
        align-items: center;
        gap: 10px;
        b{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #4F4F4F;
        }
    }
    .txt-title{
        font-family: "Roboto";
        font-style: normal;
        font-size: 12px;
        line-height: 16px;
        color: #828282;
        font-weight: 600;
    }
}
::v-deep(input) {
    border-radius: 8px;
    text-align: right;
}
</style>