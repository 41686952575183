<template>
  <div
    class="myServices-card"
    style="flex-direction: column; align-items: flex-start; gap: 8px; margin-top: 8px"
    v-for:="n in qto"
    :style="n.status === 'Ativo' ? 'opacity: 1.0' : 'opacity: .5'"
    v-if="qto.length > 0"
  >
    <div class="css-selector" style="width: 200px; max-width: 200px" />
    <div class="css-selector" style="width: 100%" />
    <div class="css-selector" style="width: 100%" />
    <div class="css-selector" style="width: 100%" />
  </div>

  <div
    class="myServices-card"
    style="flex-direction: column; align-items: flex-start; gap: 8px; margin-top: 8px"
    v-for:="n in myDefault"
    :style="n.status === 'Ativo' ? 'opacity: 1.0' : 'opacity: .5'"
    v-else
  >
    <div class="css-selector" style="width: 200px; max-width: 200px" />
    <div class="css-selector" style="width: 100%" />
    <div class="css-selector" style="width: 100%" />
    <div class="css-selector" style="width: 100%" />
  </div>
</template>

<script>
import {ref} from 'vue';
export default {
  props: ["qto"],
  setup() {
    const myDefault = ref([
      { status: "Ativo" },
      { status: "Ativo" },
      { status: "Inativo" },
    ]);
    return { myDefault };
  },
};
</script>

<style lang="scss" scoped>
.myServices-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffffff;
  border-radius: 8px;
  justify-content: space-between;
  padding: 16px;
  height: 120px;
}
.css-selector {
  height: 12px;
  border-radius: 8px;
  background: linear-gradient(270deg, white, #e6e6e6);
  background-size: 400% 400%;
  -webkit-animation: AnimationName 8s ease infinite;
  -moz-animation: AnimationName 8s ease infinite;
  -o-animation: AnimationName 8s ease infinite;
  animation: AnimationName 8s ease infinite;
}
@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-o-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
