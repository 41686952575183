<template>
    <div class="dialog-addservice-sus">
        <div :class="disabled && 'cursor-not-allowed'">
            <div :class="disabled && 'cursor-not-allowed'">
                <AutoComplete 
                    v-model="textValue" 
                    :suggestions="listSuggestions" 
                    @complete="completeItem($event)" 
                    @item-select="selectedSus($event)"
                    field="name" 
                    placeholder="Digite o código SUS"
                    :class="(error && (!sus && !susprocedure)) && 'p-invalid'"
                    :disabled="disabled"
                />
                <img
                    class="clear-input" 
                    src="../../../../assets/icons/myservices/clear-input.png" 
                    alt="" 
                    v-tooltip.left="'Limpar SUS e Descrição.'"
                    @click="clearInput"
                    v-if="viewClear"
                />
            </div>
        </div>

        <ul v-if="listSuggestionsSus.length > 0 && !textValue">
            <li v-for="item in listSuggestionsSus" :key="item.sus" @click="checkedSus(item)">
                {{item.sus}} - {{item.susprocedure}}
            </li>
        </ul>
    </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";
import {searchSus} from "./actions.js";

export default {
    props: ["sus", "susprocedure", "description", "listSuggestionsSus", "disabled", "error"],
    emits: ["update:sus", "update:susprocedure", "suggestionDescription", "update:listSuggestionsSus", "update:description"],
    setup(props, { emit }) {
        const textValue = ref("");
        const listSuggestions = ref([]);
        const viewClear = computed(() => {
            if(typeof(textValue.value) === 'object'){
                if(textValue.value){
                    return true;
                }
            }
            return false;
        })

        const completeItem = (key) => {
            setTimeout(async () => {
                if(key.query.length >= 3){
                    const arrSearch = await searchSus(key.query);
                    listSuggestions.value = arrSearch;
                }else{
                    listSuggestions.value = [];
                }
            }, 250);
        };
        
        const checkedSus = (item) => {
            textValue.value = item;      
            emit("update:sus", item.sus); 
            emit("update:susprocedure", item.susprocedure); 
            emit("suggestionDescription"); 
        };

        const clearInput = () =>{
            textValue.value = ""
            emit("update:sus", "");
            emit("update:susprocedure", "");
            emit("update:description", "");
        }

        const selectedSus = ({value}) => {
            const { code, susterminology } = value;
            emit("update:sus", code);
            emit("update:susprocedure", susterminology);
        }

        watch( () => props.sus, (newValue, oldValue) => {
            
            if(!newValue){
                if(textValue.value){
                    textValue.value = "";
                    emit("update:listSuggestionsSus", []);
                }
            }
        });

        return {
            textValue,
            listSuggestions,
            completeItem,
            checkedSus,
            viewClear,
            clearInput,
            selectedSus
        }
    },
};
</script>


<style lang="scss" scoped>
.dialog-addservice-sus {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    ul{
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
        max-height: 200px;
        gap: 6px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 8px;
            cursor: pointer;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #e0e0e0;
            border-radius: 25px;
        }

        &::-webkit-scrollbar-track {
            margin: 10px;
        }
        
        li{
            display: flex;
            align-items: center;
            height: max-content !important;
            padding: 8px 16px;
            background: #FAFAFA;
            border: 1px solid #E9EAEC;
            border-radius: 8px;
            width: calc(100% - 10px);

            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #57595F;

            &:hover{
                border-color: #FF4500;
                cursor: pointer;
            }
        }
    }
    .clear-input{
        background: white;
        position: absolute;
        right: 8px;
        top: 13px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}

::v-deep(.p-autocomplete) {
    width: 100%;
}
::v-deep(.p-autocomplete-input) {
    border-top: 1px solid #F2F2F2;
    border-radius: 8px;
    vertical-align: middle;
    height: 45px;
    padding-right: 32px;
}
</style>