<template>
    <Dropdown 
        v-model="modelValue" 
        :class="isActive"
        :options="listNameService" 
        :value="modelValue" 
        optionLabel="name" 
        optionValue="name"
        placeholder="Nome do Serviço"
        @change="$emit('update:modelValue', $event.value)"
    />
    <p class="p-invalid" v-if="error && !modelValue">{{error}}</p>
</template>
  
<script>
import { computed } from "vue";
export default {
    props: ["modelValue", "round", "error"],
    emits: ["update:modelValue"],
    setup(props) {
        const isActive = computed(() => {
            if(props.round === 'name' && !props.modelValue){
                return 'input-select';
            }
            return 'border-gray';
        })
        const listNameService = [
            { name: "Remoção", code: "" },
            { name: "Pequena Cirurgia", code: "" },
            { name: "Outras Terapias", code: "" },
            { name: "Consulta", code: "" },
            { name: "Exame Ambulatorial", code: "" },
            { name: "Atendimento Domiciliar", code: "" },
            { name: "Internação", code: "" },
            { name: "Quimioterapia", code: "" },
            { name: "Radioterapia", code: "" },
            { name: "Terapia Renal Substitutiva (TRS)", code: "" },
            { name: "Pronto Socorro", code: "" },
            { name: "Ocupacional", code: "" },
            { name: "Pequeno atendimento (sutura, gesso e outros)", code: "" },
            { name: "Saúde Ocupacional - Admissional", code: "" },
            { name: "Saúde Ocupacional - Demissional", code: "" },
            { name: "Saúde Ocupacional - Periódico", code: "" },
            { name: "Saúde Ocupacional - Retorno ao trabalho", code: "" },
            { name: "Saúde Ocupacional - Mudança de função", code: "" },
            { name: "Saúde Ocupacional - Promoção a saúde", code: "" },
            { name: "Saúde Ocupacional - Beneficiário novo", code: "" },
            { name: "Saúde Ocupacional - Assistência a demitidos", code: "" },
            { name: "TELESSAÚDE", code: "" },
        ];

        return { listNameService, isActive };
    },
};
</script>
  
<style lang="scss" scoped>
.input-select{
    border: 1px solid #FF6A33;
}

.border-gray{
    border: 1px solid #F2F2F2 !important;
}
</style>
  