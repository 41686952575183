<template>
    <p class="p-invalid">{{ txt && txt }}</p>
</template>

<script>
// import { ref } from "vue";

export default {
    props: ["txt"],
    setup() {

        return {}
    }
}

</script>

