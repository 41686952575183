/**
 * @fileoverview gRPC-Web generated client stub for dataApi
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.18.1
// source: tuss.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.dataApi = require('./tuss_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dataApi.TussProtoServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.dataApi.TussProtoServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'binary';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataApi.ListTussSuggestionsRequest,
 *   !proto.dataApi.ListTussSuggestionsResponse>}
 */
const methodDescriptor_TussProtoService_ListTussSuggestions = new grpc.web.MethodDescriptor(
  '/dataApi.TussProtoService/ListTussSuggestions',
  grpc.web.MethodType.UNARY,
  proto.dataApi.ListTussSuggestionsRequest,
  proto.dataApi.ListTussSuggestionsResponse,
  /**
   * @param {!proto.dataApi.ListTussSuggestionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataApi.ListTussSuggestionsResponse.deserializeBinary
);


/**
 * @param {!proto.dataApi.ListTussSuggestionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataApi.ListTussSuggestionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataApi.ListTussSuggestionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataApi.TussProtoServiceClient.prototype.listTussSuggestions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataApi.TussProtoService/ListTussSuggestions',
      request,
      metadata || {},
      methodDescriptor_TussProtoService_ListTussSuggestions,
      callback);
};


/**
 * @param {!proto.dataApi.ListTussSuggestionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataApi.ListTussSuggestionsResponse>}
 *     Promise that resolves to the response
 */
proto.dataApi.TussProtoServicePromiseClient.prototype.listTussSuggestions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataApi.TussProtoService/ListTussSuggestions',
      request,
      metadata || {},
      methodDescriptor_TussProtoService_ListTussSuggestions);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataApi.SearchTussItemsRequest,
 *   !proto.dataApi.SearchTussItemsResponse>}
 */
const methodDescriptor_TussProtoService_SearchTussItems = new grpc.web.MethodDescriptor(
  '/dataApi.TussProtoService/SearchTussItems',
  grpc.web.MethodType.UNARY,
  proto.dataApi.SearchTussItemsRequest,
  proto.dataApi.SearchTussItemsResponse,
  /**
   * @param {!proto.dataApi.SearchTussItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataApi.SearchTussItemsResponse.deserializeBinary
);


/**
 * @param {!proto.dataApi.SearchTussItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataApi.SearchTussItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataApi.SearchTussItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataApi.TussProtoServiceClient.prototype.searchTussItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataApi.TussProtoService/SearchTussItems',
      request,
      metadata || {},
      methodDescriptor_TussProtoService_SearchTussItems,
      callback);
};


/**
 * @param {!proto.dataApi.SearchTussItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataApi.SearchTussItemsResponse>}
 *     Promise that resolves to the response
 */
proto.dataApi.TussProtoServicePromiseClient.prototype.searchTussItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataApi.TussProtoService/SearchTussItems',
      request,
      metadata || {},
      methodDescriptor_TussProtoService_SearchTussItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataApi.SearchSusItemsRequest,
 *   !proto.dataApi.SearchSusItemsResponse>}
 */
const methodDescriptor_TussProtoService_SearchSusItems = new grpc.web.MethodDescriptor(
  '/dataApi.TussProtoService/SearchSusItems',
  grpc.web.MethodType.UNARY,
  proto.dataApi.SearchSusItemsRequest,
  proto.dataApi.SearchSusItemsResponse,
  /**
   * @param {!proto.dataApi.SearchSusItemsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataApi.SearchSusItemsResponse.deserializeBinary
);


/**
 * @param {!proto.dataApi.SearchSusItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataApi.SearchSusItemsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataApi.SearchSusItemsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataApi.TussProtoServiceClient.prototype.searchSusItems =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataApi.TussProtoService/SearchSusItems',
      request,
      metadata || {},
      methodDescriptor_TussProtoService_SearchSusItems,
      callback);
};


/**
 * @param {!proto.dataApi.SearchSusItemsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataApi.SearchSusItemsResponse>}
 *     Promise that resolves to the response
 */
proto.dataApi.TussProtoServicePromiseClient.prototype.searchSusItems =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataApi.TussProtoService/SearchSusItems',
      request,
      metadata || {},
      methodDescriptor_TussProtoService_SearchSusItems);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataApi.ListTussCorrelatedToSusRequest,
 *   !proto.dataApi.ListTussCorrelatedToSusResponse>}
 */
const methodDescriptor_TussProtoService_ListTussCorrelatedToSus = new grpc.web.MethodDescriptor(
  '/dataApi.TussProtoService/ListTussCorrelatedToSus',
  grpc.web.MethodType.UNARY,
  proto.dataApi.ListTussCorrelatedToSusRequest,
  proto.dataApi.ListTussCorrelatedToSusResponse,
  /**
   * @param {!proto.dataApi.ListTussCorrelatedToSusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataApi.ListTussCorrelatedToSusResponse.deserializeBinary
);


/**
 * @param {!proto.dataApi.ListTussCorrelatedToSusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataApi.ListTussCorrelatedToSusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataApi.ListTussCorrelatedToSusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataApi.TussProtoServiceClient.prototype.listTussCorrelatedToSus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataApi.TussProtoService/ListTussCorrelatedToSus',
      request,
      metadata || {},
      methodDescriptor_TussProtoService_ListTussCorrelatedToSus,
      callback);
};


/**
 * @param {!proto.dataApi.ListTussCorrelatedToSusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataApi.ListTussCorrelatedToSusResponse>}
 *     Promise that resolves to the response
 */
proto.dataApi.TussProtoServicePromiseClient.prototype.listTussCorrelatedToSus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataApi.TussProtoService/ListTussCorrelatedToSus',
      request,
      metadata || {},
      methodDescriptor_TussProtoService_ListTussCorrelatedToSus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataApi.ListSusCorrelatedToTussRequest,
 *   !proto.dataApi.ListSusCorrelatedToTussResponse>}
 */
const methodDescriptor_TussProtoService_ListSusCorrelatedToTuss = new grpc.web.MethodDescriptor(
  '/dataApi.TussProtoService/ListSusCorrelatedToTuss',
  grpc.web.MethodType.UNARY,
  proto.dataApi.ListSusCorrelatedToTussRequest,
  proto.dataApi.ListSusCorrelatedToTussResponse,
  /**
   * @param {!proto.dataApi.ListSusCorrelatedToTussRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataApi.ListSusCorrelatedToTussResponse.deserializeBinary
);


/**
 * @param {!proto.dataApi.ListSusCorrelatedToTussRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataApi.ListSusCorrelatedToTussResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataApi.ListSusCorrelatedToTussResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataApi.TussProtoServiceClient.prototype.listSusCorrelatedToTuss =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataApi.TussProtoService/ListSusCorrelatedToTuss',
      request,
      metadata || {},
      methodDescriptor_TussProtoService_ListSusCorrelatedToTuss,
      callback);
};


/**
 * @param {!proto.dataApi.ListSusCorrelatedToTussRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataApi.ListSusCorrelatedToTussResponse>}
 *     Promise that resolves to the response
 */
proto.dataApi.TussProtoServicePromiseClient.prototype.listSusCorrelatedToTuss =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataApi.TussProtoService/ListSusCorrelatedToTuss',
      request,
      metadata || {},
      methodDescriptor_TussProtoService_ListSusCorrelatedToTuss);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.dataApi.SuggestionDescriptionRequest,
 *   !proto.dataApi.SuggestionDescriptionResponse>}
 */
const methodDescriptor_TussProtoService_SuggestionDescription = new grpc.web.MethodDescriptor(
  '/dataApi.TussProtoService/SuggestionDescription',
  grpc.web.MethodType.UNARY,
  proto.dataApi.SuggestionDescriptionRequest,
  proto.dataApi.SuggestionDescriptionResponse,
  /**
   * @param {!proto.dataApi.SuggestionDescriptionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.dataApi.SuggestionDescriptionResponse.deserializeBinary
);


/**
 * @param {!proto.dataApi.SuggestionDescriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.dataApi.SuggestionDescriptionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.dataApi.SuggestionDescriptionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.dataApi.TussProtoServiceClient.prototype.suggestionDescription =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/dataApi.TussProtoService/SuggestionDescription',
      request,
      metadata || {},
      methodDescriptor_TussProtoService_SuggestionDescription,
      callback);
};


/**
 * @param {!proto.dataApi.SuggestionDescriptionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.dataApi.SuggestionDescriptionResponse>}
 *     Promise that resolves to the response
 */
proto.dataApi.TussProtoServicePromiseClient.prototype.suggestionDescription =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/dataApi.TussProtoService/SuggestionDescription',
      request,
      metadata || {},
      methodDescriptor_TussProtoService_SuggestionDescription);
};


module.exports = proto.dataApi;

