<template>
    <div class="legend">
        <h1>Legenda de serviços oferecidos</h1>

        <div class="modes flex align-items-center">
            <div class="mode" v-for="mode in listModes">
                <div class="tag" :style="{background: mode.color}" />
                <b>{{mode.name}}</b>
            </div>
        </div>
    </div>
</template>

<script>
// import { onMounted, ref } from "vue";

const listModes = [
    {name: "Presencial", color: "#A2C0D4"},
    {name: "Videochamada", color: "#333333"},
    {name: "Homecare", color: "#FF4500"},
]

export default {
    // props: [""],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {

        return {
            listModes,
        }
    },
};
</script>


<style lang="scss" scoped>
.legend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 20px;
    margin-bottom: 8px;

    background: white;
    border-radius: 8px;


    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #828282;
    }

    b {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #BDBDBD;
    }
    
    .modes{
        display: flex;
        align-items: center;
        gap: 24px;
        .mode{
            display: flex;
            align-items: center;
            gap: 4px;
            .tag{
                width: 4px;
                height: 8px;
                border-radius: 8px;
            }
        }
    }
}


</style>