<template>
  <Dialog class="modal" :style="{
    width: '402px',
    borderRadius: '8px',
  }" :visible="visible" @hide="close" @update:visible="onUpdate">
    <template #header>
      <h5 class="m-auto">Adicionar Serviço</h5>
    </template>
    <div class="content">
      <Dropdown v-model="nameService" :options="listNameService" optionLabel="name" optionValue="name"
        placeholder="Nome do Serviço" :class="(msgErros.name && !nameService) && 'p-invalid'" />
      <Error :txt="msgErros.name" v-if="msgErros.name && !nameService" />
      <div class="mtop-8">Profissão</div>

      <div class="mtop-8" v-for:="concil_id in councilId" style="position: relative">
        <div class="vertical" />
        <div class="field-checkbox">
          <RadioButton name="council" :value="{ councilId: concil_id.councilid }" v-model="valueRadio"
            :disabled="!nameServiceIsSelected()" />
          <label for="binary" class="text-bold">
            {{ concil_id.council && concil_id.council }} -
            {{ concil_id.state && concil_id.state }}
          </label>
        </div>
        <div class="mleft-20" style="position: relative" v-if="valueRadio.councilId === concil_id.councilid"
          v-for:="speciality in concil_id.specialityList">
          <div class="vertical" />
          <div class="horizontal" />
          <div class="field-checkbox">
            <Checkbox id="binary" v-model="valueCheckBox" :value="{
              councilId: concil_id.councilid,
              specialityId: speciality.specialtyid,
              name: speciality.description,
              type: 'speciality',
            }" :trueValue="true" :falseValue="false" />
            <label for="binary">{{ speciality.description }}</label>
          </div>
          <div class="mleft-20" style="position: relative" v-for:="actuation in speciality.actuationList"
            v-if="isSpeciality(speciality.specialtyid)">
            <div class="horizontal"></div>
            <div class="field-checkbox">
              <Checkbox id="binary" v-model="valueCheckBox" :value="{
                councilId: concil_id.councilid,
                specialityId: speciality.specialtyid,
                actionActuationId: actuation.actionactuationid,
                name: actuation.description,
                type: 'actuation',
              }" />
              <label for="binary">{{ actuation.description }}</label>
            </div>
          </div>
        </div>
      </div>
      <Error :txt="msgErros.serviceHasSpecialities"
        v-if="msgErros.serviceHasSpecialities && validateField(valueRadio.councilId)" />


      <!-- TODO: TUSS -->
      <div class="box-tuss" v-if="isMedical()">
        <AutoComplete field="name" :style="{
          width: tuss.length > 0 && (!isTussSelected.type && !isTussSelected.data) ? 'calc(100% - 20px)' : '100%',
        }" v-model="inputSearchTuss" :suggestions="listSearchTussItems" placeholder="Digite o TUSS"
          :disabled="!nameServiceIsSelected() || valueRadio.councilId === null" @complete="completeSearchTuss($event)"
          @item-select="selectedSearchTuss($event)" @clear="clearTuss"
          :class="msgErros.tuss && isMedical() && validateField(isTussSelected.type, isSusSelected.type) && 'p-invalid'" />
        <div class="box-sugestions" v-if="tuss.length > 0 && !isTussSelected.type && !isTussSelected.data.tuss">
          <div class="item" v-for="item in tuss" :key="item.tuss" @click="selectTussFromList(item)"
            :style="isTussSelected.data === item && 'border-color: #ff6a33'">
            {{ item.tuss }} - {{ item.tussterm }}
          </div>
        </div>
      </div>

      <!-- TODO: SUS -->
      <div class="box-sus" style="margin-top: 8px" v-if="isMedical()">
        <AutoComplete field="name" :style="{
          width: sus.length > 0 && (!isSusSelected.type || !isSusSelected.data) ? 'calc(100% - 20px)' : '100%',
        }" v-model="inputSearchSus" :suggestions="listSearchSusItems" placeholder="Digite o SUS"
          :disabled="!nameServiceIsSelected() || valueRadio.councilId === null" @complete="completeSearchSus($event)"
          @item-select="selectedSearchSus($event)" @clear="clearSus"
          :class="msgErros.tuss && isMedical() && validateField(isTussSelected.type, isSusSelected.type) && 'p-invalid'" />
        <div class="box-sugestions" v-if="sus.length > 0 && (!isSusSelected.type || !isSusSelected.data)">
          <div class="item" v-for="item in sus" :key="item.sus" @click="selectSusFromList(item)"
            :style="isSusSelected.data === item && 'border-color: #ff6a33'">
            {{ item.sus }} - {{ item.susprocedure }}
          </div>
        </div>
      </div>
      <Error :txt="msgErros.tuss"
        v-if="msgErros.tuss && isMedical() && validateField(isTussSelected.type, isSusSelected.type)" />

      <!-- Description -->
      <div class="box-sus" style="background: none;">
        <Textarea class="txt-area" placeholder="Descrição" v-model="description" :value="description"
          :autoResize="false" rows="5" cols="30" :disabled="!nameServiceIsSelected() || valueRadio.councilId === null"
          @input="description = $event.target.value"
          :class="(validation && msgErros.description && !description) && 'p-invalid'" />
        <Error :txt="msgErros.description" v-if="validation && msgErros.description && !description" />
      </div>


      <!-- <InputText class="mtop-8" id="sus" placeholder="Digite o SUS" :disabled="!nameServiceIsSelected()" /> -->

      <Divider />

      <!-- Retorno Consulta -->
      <div class="flex flex-column">
        <div class="flex justify-content-between align-items-center">
          <span class="text-label">Direito a retorno após a consulta</span>
          <InputSwitch v-model="medicalReturn.active" :disabled="!nameServiceIsSelected()" />
        </div>
        <div v-if="medicalReturn.active" class="flex justify-content-between align-items-center mt-3">
          <label for="duration" class="fs-2 mb-1">
            Número máximo de dias para o retorno:
          </label>
          <!-- timeReturn -->
          <InputNumber id="expiry" class="txt-right" :style="{ width: '100px' }" v-model="medicalReturn.maxDays"
            placeholder="0 dia(s)" suffix=" dia(s)" :min="1" />
        </div>
        <Error class="text-right" :txt="msgErros.timeReturn" v-if="validation && msgErros.timeReturn && !medicalReturn.maxDays" />
        <Divider />
      </div>

      <!-- Presencial -->
      <div class="flex flex-column">
        <div class="flex justify-content-between align-items-center">
          <div class="flex align-items-center">
            <div class="tag-color" />
            <span class="text-label mleft-8">Presencial</span>
          </div>

          <InputSwitch v-model="inPerson.active" :disabled="!nameServiceIsSelected()" />
        </div>
        <div v-if="inPerson.active" class="flex mt-3">
          <div class="flex flex-column w-6 pr-2">
            <label for="duration" class="text-bold span-color fs-2 mb-1"> Duração</label>
            <Calendar class="txt-right" v-model="inPerson.duration" :timeOnly="true" :showTime="true"
              placeholder="00:00"
              :class="(validation && msgErros.Presencial.duration && inPerson.active && valideDuration(inPerson.duration)) && 'p-invalid'" />
            <Error :txt="msgErros.Presencial.duration"
              v-if="validation && msgErros.Presencial.duration && inPerson.active && valideDuration(inPerson.duration)" />
          </div>
          <div class="flex flex-column w-6 pl-2">
            <label for="price" class="text-bold span-color fs-2 mb-1"> Valor </label>
            <InputNumber class="txt-right" v-model="inPerson.value" mode="currency" currency="BRL" locale="pt-BR"
              placeholder="R$ 0,00"
              :class="msgErros.Presencial.currency && validation && inPerson.active && msgErros.Presencial.currency && !inPerson.value && 'p-invalid'" />
            <Error :txt="msgErros.Presencial.currency"
              v-if="validation && inPerson.active && msgErros.Presencial.currency && !inPerson.value" />
          </div>
        </div>

        <Divider />
      </div>

      <!-- VideoChamada -->
      <div class="flex flex-column">
        <div class="flex justify-content-between align-items-center">
          <div class="flex align-items-center">
            <div class="tag-color" style="background: #2d313d" />
            <span class="text-label mleft-8">Videochamada</span>
          </div>
          <InputSwitch v-model="videoCall.active" :disabled="!nameServiceIsSelected()" />
        </div>
        <div v-if="videoCall.active" class="d-flex mt-3">
          <div class="flex flex-column w-6 pr-2">
            <label for="duration" class="text-bold span-color fs-2 mb-1"> Duração </label>
            <Calendar class="txt-right" v-model="videoCall.duration" :timeOnly="true" :showTime="true"
              placeholder="00:00"
              :class="(validation && msgErros.Videochamada.duration && videoCall.active && valideDuration(videoCall.duration)) && 'p-invalid'" />
            <Error :txt="msgErros.Videochamada.duration"
              v-if="validation && msgErros.Videochamada.duration && videoCall.active && valideDuration(videoCall.duration)" />
          </div>
          <div class="flex flex-column w-6 pl-2">
            <label for="price" class="text-bold span-color fs-2 mb-1"> Valor </label>
            <InputNumber class="txt-right" v-model="videoCall.value" mode="currency" currency="BRL" locale="pt-BR"
              placeholder="R$ 0,00"
              :class="msgErros.Videochamada.currency && validation && videoCall.active && msgErros.Videochamada.currency && !videoCall.value && 'p-invalid'" />
            <Error :txt="msgErros.Videochamada.currency"
              v-if="validation && videoCall.active && msgErros.Videochamada.currency && !videoCall.value" />
          </div>
        </div>
        <Error txt="É obrigatório preencher os campos de duração e valor."
          v-if="validation && (videoCall.active && !getVideoCall().active)" />
        <Divider />
      </div>

      <!-- HomeCare -->
      <div class="flex flex-column">
        <div class="flex justify-content-between align-items-center">
          <div class="flex align-items-center">
            <div class="tag-color" style="background: #ff6a33" />
            <span class="text-label mleft-8">Homecare</span>
          </div>
          <InputSwitch v-model="homeCare.active" :disabled="!nameServiceIsSelected()" />
        </div>

        <div v-if="homeCare.active" class="flex flex-column mt-3">
          <div class="flex">
            <div class="flex flex-column w-6 pr-2">
              <label for="duration" class="text-bold span-color fs-2 mb-1">
                Duração
              </label>
              <Calendar class="txt-right" v-model="homeCare.duration" :timeOnly="true" :showTime="true"
                placeholder="00:00" />
            </div>
            <div class="flex flex-column w-6 pl-2">
              <label for="price" class="text-bold span-color fs-2 mb-1"> Valor </label>
              <InputNumber class="txt-right" v-model="homeCare.value" mode="currency" currency="BRL" locale="pt-BR"
                placeholder="R$ 0,00"
                :class="msgErros.Homecare.currency && validation && homeCare.active && msgErros.Homecare.currency && !homeCare.value && 'p-invalid'" />
              <Error :txt="msgErros.Homecare.currency"
                v-if="validation && homeCare.active && msgErros.Homecare.currency && !homeCare.value" />
            </div>
          </div>
          <div>
            <div class="my-2">
              <span class="fs-2">Deslocamento</span>
            </div>
            <div class="flex">
              <div class="flex flex-column w-6 pr-2">
                <label for="price" class="text-bold span-color fs-2 mb-1">
                  Valor por Km
                </label>

                <InputNumber id="expiry" mode="currency" currency="BRL" locale="pt-BR" class="txt-right"
                  v-model="homeCare.km" suffix=" / Km" placeholder="R$ 0,00 / Km" />
              </div>
              <div class="flex flex-column w-6 pl-2">
                <label for="price" class="text-bold span-color fs-2 mb-1">
                  Raio de abrangência
                </label>
                <InputNumber id="expiry" class="txt-right" v-model="homeCare.raio" suffix=" Km" placeholder="0 Km"
                  :class="msgErros.Homecare.raio && validation && homeCare.active && msgErros.Homecare.raio && !homeCare.raio && 'p-invalid'" />
                <Error :txt="msgErros.Homecare.raio"
                  v-if="validation && homeCare.active && msgErros.Homecare.raio && !homeCare.raio" />
              </div>
            </div>
          </div>
        </div>
        <Error txt="É obrigatório preencher todos os campos acima."
          v-if="validation && (homeCare.active && !getHomeCare().active)" />
      </div>
      <Error :txt="msgErros.professionalServiceTypes"
        v-if="validation && msgErros.professionalServiceTypes && validateField(inPerson.active, videoCall.active, homeCare.active)" />
    </div>

    <template #footer>
      <div class="flex justify-content-end align-items-center h-full">
        <Button class="btn-active" label="Salvar" @click="onSubmit" />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import {
  ListActiveRegistriesRequest,
  AddServiceRequest,
} from "../../../core/grpc/generated/professionalProfile_pb";
import {
  ListTussSuggestionsRequest,
  SearchTussItemsRequest,
  SearchSusItemsRequest,
  ListSusCorrelatedToTussRequest,
  SuggestionDescriptionRequest,
} from "../../../core/grpc/generated/tuss_pb";
import Error from "../Error";

import profileService from "../../../core/services/professionalProfileService";
import tussService from "../../../core/services/tussService";

export default {
  props: ["visible"],
  emits: ["update:visible", "serviceAddSucess"],
  components: { Error },
  setup(props, ctx) {
    const councilId = ref([]);
    const validation = ref(false);
    const arrServiceTypes = ref([])
    let tuss = ref([]);
    let sus = ref([]);
    const inputSearchTuss = ref("");
    const inputSearchSus = ref("");
    const listSearchTussItems = ref([]);
    const listSearchSusItems = ref([]);
    const nameService = ref();
    const isTussSelected = ref({
      type: null,
      data: { tuss: null, tussterm: null },
    });
    const isSusSelected = ref({
      type: null,
      data: { sus: null, susprocedure: null },
    });
    const listNameService = ref([
      { name: "Remoção", code: "" },
      { name: "Pequena Cirurgia", code: "" },
      { name: "Outras Terapias", code: "" },
      { name: "Consulta", code: "" },
      { name: "Exame Ambulatorial", code: "" },
      { name: "Atendimento Domiciliar", code: "" },
      { name: "Internação", code: "" },
      { name: "Quimioterapia", code: "" },
      { name: "Radioterapia", code: "" },
      { name: "Terapia Renal Substitutiva (TRS)", code: "" },
      { name: "Pronto Socorro", code: "" },
      { name: "Ocupacional", code: "" },
      { name: "Pequeno atendimento (sutura, gesso e outros)", code: "" },
      { name: "Saúde Ocupacional - Admissional", code: "" },
      { name: "Saúde Ocupacional - Demissional", code: "" },
      { name: "Saúde Ocupacional - Periódico", code: "" },
      { name: "Saúde Ocupacional - Retorno ao trabalho", code: "" },
      { name: "Saúde Ocupacional - Mudança de função", code: "" },
      { name: "Saúde Ocupacional - Promoção a saúde", code: "" },
      { name: "Saúde Ocupacional - Beneficiário novo", code: "" },
      { name: "Saúde Ocupacional - Assistência a demitidos", code: "" },
      { name: "TELESSAÚDE", code: "" },
    ]);
    let description = ref("");
    const valueRadio = ref({ councilId: null });
    const valueCheckBox = ref([]);
    const medicalReturn = ref({
      active: false,
      maxDays: 0,
    });
    const inPerson = ref({
      active: false,
      duration: new Date("Tue Jun 14 2022 00:00:00 GMT-0300 (Hora padrão de Brasília)"),
      value: 0,
    });
    const videoCall = ref({
      active: false,
      duration: new Date("Tue Jun 14 2022 00:00:00 GMT-0300 (Hora padrão de Brasília)"),
      value: 0,
    });
    const homeCare = ref({
      active: false,
      duration: new Date("Tue Jun 14 2022 00:00:00 GMT-0300 (Hora padrão de Brasília)"),
      value: 0,
      km: 0,
      raio: 0,
    });

    const msgErros = ref({
      name: null,
      professionalServiceTypes: null,
      description: null,
      Presencial: { duration: null, currency: null },
      Videochamada: { duration: null, currency: null },
      Homecare: { duration: null, currency: null, km: null },
    })


    const professionList = async () => {
      const request = new ListActiveRegistriesRequest();
      const resp = await profileService.listActiveRegistries(request);

      if (resp.success == true) {
        console.log(resp);
        councilId.value = resp.councilprofessionalsList;
      }
    };

    const tussSugestionList = async (listSpecialty) => {
      const req = new ListTussSuggestionsRequest();
      req.setSpecialtyList(listSpecialty);
      const res = await tussService.listTussSuggestion(req);

      if (res.success) {
        console.log(res);
        tuss.value = res.correlationsList;
      }
    };

    const destructName = () => {
      const names = [];
      valueCheckBox.value.map((item) => names.push(item.name));
      if (names.length > 0) {
        return names;
      }
      return [""];
    };

    const close = () => ctx.emit("update:visible", false);

    const onUpdate = (val) => (!val) && ctx.emit("update:visible", val);

    const getMedicalReturn = () => {
      const mr = medicalReturn.value;
      const medical = {
        active: mr.active,
        maxDays: mr.maxDays
      }

      return medical;
    };

    const getPerson = () => {
      const ip = inPerson.value;
      const date = new Date(String(inPerson.value.duration));
      const minutes = date.getHours() * 60 + date.getMinutes();
      const person = {
        active: ip.active,
        duration: isNaN(minutes),
        value: isNaN(ip.value),
      };

      return person;
    };

    const getVideoCall = () => {
      const vc = videoCall.value;
      const date = new Date(String(vc.duration));
      const minutes = date.getHours() * 60 + date.getMinutes();
      const video_call = {
        active: vc.active,
        duration: minutes,
        value: vc.value
      }

      return video_call;
    };

    const getHomeCare = () => {
      const hc = homeCare.value;
      const date = new Date(String(hc.duration));
      const minutes = date.getHours() * 60 + date.getMinutes();
      const home_care = {
        active: hc.active,
        duration: minutes,
        value: hc.value,
        km: hc.km,
        raio: hc.raio
      };

      return home_care;
    };

    const isNaN = (number) => {
      if (number) {
        return number
      }

      return 0
    }

    const clearErros = () => {
      msgErros.value = {
        name: null,
        professionalServiceTypes: null,
        description: null,
        Presencial: { duration: null, currency: null },
        Videochamada: { duration: null, currency: null },
        Homecare: { duration: null, currency: null, km: null },
      }
    }

    const valideDuration = (duration) => {
      if (!duration || String(duration) === ('Tue Jun 14 2022 00:00:00 GMT-0300 (Horário Padrão de Brasília)')) {
        return true
      }
      return false
    }

    const addService = async (obj) => {
      console.log(obj)
      arrServiceTypes.value = []
      const req = new AddServiceRequest();
      req.setName(obj.name);

      req.setTuss(obj.tuss.data.tuss);
      req.setTussterm(obj.tuss.data.tussterm);
      req.setSus(obj.sus.data.sus);
      req.setSusprocedure(obj.sus.data.susprocedure);


      req.setDescription(obj.description);
      // req.setTimeReturn(nameService);

      if (obj.service_has_specialities[0].councilId !== null) {
        obj.service_has_specialities.forEach((element) => {
          var specialities = new proto.professionalProfileApi.ServiceHasSpecialities();
          if (element.councilId) {
            specialities.setCouncilid(element.councilId);
          }
          if (element.specialityId) {
            specialities.setSpecialityid(element.specialityId);
          }
          if (element.actionActuationId) {
            specialities.setActionactuationid(element.actionActuationId);
          }
          req.addServiceHasSpecialities(specialities);
        });
      }

      if (obj.medicalReturn.active) {
        req.setReturn(obj.medicalReturn.active);
        req.setTimeReturn(obj.medicalReturn.maxDays);
      }

      if (obj.inPerson.active) {
        var serviceTypes = new proto.professionalProfileApi.ProfessionalServiceTypes();
        serviceTypes.setServicemode("Presencial");
        serviceTypes.setEstimatedTime(obj.inPerson.duration);
        serviceTypes.setValue(obj.inPerson.value);
        req.addProfessionalServiceTypes(serviceTypes);
        arrServiceTypes.value = [...arrServiceTypes.value, "Presencial"];
      }

      if (obj.videoCall.active) {
        var serviceTypes = new proto.professionalProfileApi.ProfessionalServiceTypes();
        serviceTypes.setServicemode("Videochamada");
        serviceTypes.setEstimatedTime(obj.videoCall.duration);
        serviceTypes.setValue(obj.videoCall.value);
        req.addProfessionalServiceTypes(serviceTypes);
        arrServiceTypes.value = [...arrServiceTypes.value, "Videochamada"];
      }

      if (obj.homeCare.active) {
        var serviceTypes = new proto.professionalProfileApi.ProfessionalServiceTypes();
        serviceTypes.setServicemode("Homecare");
        serviceTypes.setEstimatedTime(obj.homeCare.duration);
        serviceTypes.setValue(obj.homeCare.value);
        serviceTypes.setMaxDisplacement(obj.homeCare.raio);
        serviceTypes.setValuekm(obj.homeCare.km);
        req.addProfessionalServiceTypes(serviceTypes);
        arrServiceTypes.value = [...arrServiceTypes.value, "Homecare"];
      }

      const res = await profileService.addService(req);

      if (res.success) {
        console.log(res);
        ctx.emit("serviceAddSucess", true);
        ctx.emit("update:visible", false);
      } else {
        const data = JSON.parse(res.data)
        clearErros()
        // console.log(arrServiceTypes.value);
        console.log(data)

        for (const property in data) {
          const msg = data[property][0]
          switch (property) {
            case 'professionalServiceTypes[0].Value':
              msgErros.value[arrServiceTypes.value[0]].currency = msg
              break;
            case 'professionalServiceTypes[0].EstimatedTime':
              msgErros.value[arrServiceTypes.value[0]].duration = msg
              break;
            case 'professionalServiceTypes[0].MaxDisplacement':
              msgErros.value[arrServiceTypes.value[0]].raio = msg
              break;
            case 'professionalServiceTypes[1].Value':
              msgErros.value[arrServiceTypes.value[1]].currency = msg
              break;
            case 'professionalServiceTypes[1].EstimatedTime':
              msgErros.value[arrServiceTypes.value[1]].duration = msg
              break;
            case 'professionalServiceTypes[1].MaxDisplacement':
              msgErros.value[arrServiceTypes.value[1]].raio = msg
              break;
            case 'professionalServiceTypes[2].Value':
              msgErros.value[arrServiceTypes.value[2]].currency = msg
              break;
            case 'professionalServiceTypes[2].EstimatedTime':
              msgErros.value[arrServiceTypes.value[2]].duration = msg
              break;
            case 'professionalServiceTypes[2].MaxDisplacement':
              msgErros.value[arrServiceTypes.value[2]].raio = msg
              break;
            case 'professionalServiceTypes':
              msgErros.value.professionalServiceTypes = msg
              break;
            default:
              msgErros.value[property] = msg
          }
        }
        console.log(msgErros.value)
      }
    };

    const onSubmit = () => {
      const list = {
        actuation: 1,
        speciality: 2
      }
      const valueCheck = valueCheckBox.value.sort((a, b) => list[a.type] - list[b.type])
      const service_has_specialities = [];

      // ADD Speciality & actuation for specialities
      valueCheck.map((item) => {
        if (item.actionActuationId) {
          service_has_specialities.push({
            councilId: item.councilId,
            specialityId: item.specialityId,
            actionActuationId: item.actionActuationId,
          });
        } else {
          const exist = service_has_specialities.find((element) => element.specialityId === item.specialityId);
          if(!exist) {
            service_has_specialities.push({
              councilId: item.councilId,
              specialityId: item.specialityId,
            });
          }
        }
      });

      if(service_has_specialities.length === 0){
        service_has_specialities.push(valueRadio.value)
      }

      const objAdd = {
        name: nameService.value,
        description: description.value,
        service_has_specialities: service_has_specialities,
        medicalReturn: getMedicalReturn(),
        inPerson: getPerson(),
        videoCall: getVideoCall(),
        homeCare: getHomeCare(),
        tuss: isTussSelected.value,
        sus: isSusSelected.value,
        ismedical: isMedical(),
      };
      // console.log(objAdd);
      addService(objAdd);
      validation.value = true;
    };

    const isSpeciality = (specialityid) => {
      const isOne = valueCheckBox.value.find(
        (item) => item.specialityId === specialityid
      );
      if (isOne) {
        return true;
      }
      return false;
    };

    const completeSearchTuss = (event) => {
      console.log(event.query);
      setTimeout(async () => {
        const request = new SearchTussItemsRequest();
        request.setKey(event.query);

        const resp = await tussService.searchTussItems(request);

        if (resp.success == true) {
          const arr = [];
          console.log(resp.tussesList);
          resp.tussesList.map((item) => {
            arr.push({ ...item, name: `${item.code} - ${item.tussterminology}` });
          });
          listSearchTussItems.value = arr;
        }
      }, 250);
    };

    const completeSearchSus = (event) => {
      console.log(event.query);
      setTimeout(async () => {
        const request = new SearchSusItemsRequest();
        request.setKey(event.query);

        const resp = await tussService.searchSusItems(request);

        if (resp.success == true) {
          const arr = [];
          console.log(resp);
          resp.sussesList.map((item) => {
            arr.push({ ...item, name: `${item.code} - ${item.susterminology}` });
          });
          listSearchSusItems.value = arr;
        }
      }, 250);
    };

    const nameServiceIsSelected = () => {
      if (nameService.value) {
        return true;
      }
      return false;
    };

    const selectTussFromList = async (item) => {
      const obj = { ...item, name: `${item.tuss} - ${item.tussterm}` };
      inputSearchTuss.value = obj;
      console.log(obj);
      const request = new ListSusCorrelatedToTussRequest();
      request.setTusskey(item.tuss);

      const resp = await tussService.listSusCorrelatedToTuss(request);

      if (resp.success == true) {
        console.log(resp);
        sus.value = resp.correlationsList
      }

      isTussSelected.value = {
        type: "list",
        data: obj,
      };
    };

    const selectSusFromList = async (item) => {
      console.log(item)
      const obj = { ...item, name: `${item.sus} - ${item.susprocedure}` };
      inputSearchSus.value = obj;

      isSusSelected.value = {
        type: "list",
        data: obj,
      };
    };

    const selectedSearchTuss = async (event) => {
      const request = new ListSusCorrelatedToTussRequest();
      request.setTusskey(event.value.code);

      const resp = await tussService.listSusCorrelatedToTuss(request);

      if (resp.success == true) {
        console.log(resp);
        sus.value = resp.correlationsList
      }
      // console.log(event.value.code)
      isTussSelected.value = {
        type: "autocomplete",
        data: event.value,
      };
    };

    const selectedSearchSus = async (event) => {
      console.log(event)

      isSusSelected.value = {
        type: "autocomplete",
        data: event.value,
      };
    };

    const clearTuss = () => {
      inputSearchSus.value = ""
      sus.value = []
      isTussSelected.value = {
        type: null,
        data: { tuss: undefined, tussterm: undefined },
      };
      isSusSelected.value = {
        type: null,
        data: { sus: undefined, susprocedure: undefined },
      };
      description.value = ""
    };

    const clearSus = () => {
      isSusSelected.value = {
        type: null,
        data: { sus: undefined, susprocedure: undefined },
      };
    };

    const isMedical = () => {
      const radioButton = valueRadio.value
      const councilSelected = radioButton.councilId

      if (councilSelected === null) {
        return true
      } else {
        const nameProfession = councilId.value.find(item => item.councilid === councilSelected);
        // console.log(nameProfession[0].council)

        if (nameProfession.council === 'Médico(a)') {
          return true
        }
      }

      return false
    }

    const correctFields = () => {
      const registerIsMedical = isMedical() && valueRadio.value.councilId
      const name_service = nameService.value
      const desc = description.value
      const council_id = valueRadio.value.councilId
      const in_person = getPerson()
      const video_call = getVideoCall()
      const home_care = getHomeCare()
      const oneServiceComplete = in_person.active || video_call.active || home_care.active

      if (registerIsMedical) {
        const leastOneService = (inPerson.value.active || videoCall.value.active || homeCare.value.active)
        const leastTussOrSus = (isTussSelected.value.data || isSusSelected.value.data)

        if (name_service && council_id && desc && leastTussOrSus && leastOneService && oneServiceComplete) {
          return true
        }
      } else {
        if (name_service && council_id && oneServiceComplete && desc) {
          return true
        }
      }
      return false
    }

    const validateField = (val1, val2, val3) => {
      if (validation.value) {
        if (val1) {
          return false
        }
        if (val2) {
          return false
        }
        if (val3) {
          return false
        }
        return true
      }
      return false
    }

    watch(
      () => inputSearchSus.value,
      async () => {
        const boolean = Boolean(inputSearchTuss.value && inputSearchSus.value)

        if (boolean) {
          const request = new SuggestionDescriptionRequest();
          request.setTuss(inputSearchTuss.value.tuss)
          request.setSus(inputSearchSus.value.sus)
          const resp = await tussService.suggestionDescription(request);

          if (resp.success == true) {
            console.log(resp);
            description.value = resp.data
          }
        } else {
          console.log('Só uma opção marcada.')
        }

      }
    );

    watch(
      () => inputSearchTuss.value,
      async () => {
        const boolean = Boolean(inputSearchTuss.value && inputSearchSus.value)

        if (boolean) {
          const request = new SuggestionDescriptionRequest();
          request.setTuss(inputSearchTuss.value.tuss)
          request.setSus(inputSearchSus.value.sus)
          const resp = await tussService.SuggestionDescription(request);

          if (resp.success == true) {
            console.log(resp);
            description.value = resp.data
          }
        } else {
          console.log('Só uma opção marcada.')
        }

      }
    );

    watch(
      () => valueRadio.value,
      (newValue, oldValue) => {
        if (councilId.value) {
          if (valueCheckBox.value.length > 0) {
            let clearCheck = valueCheckBox.value.filter(
              (item) => item.councilId === valueRadio.value.councilId
            );
            valueCheckBox.value = clearCheck;
            console.log(clearCheck)
          }
        }
      }
    );

    watch(
      () => inPerson.value.duration,
      (newValue, oldValue) => {
        if (!newValue) {
          inPerson.value.duration = new Date("Tue Jun 14 2022 00:00:00 GMT-0300 (Hora padrão de Brasília)")
        }
      }
    );

    watch(
      () => videoCall.value.duration,
      (newValue, oldValue) => {
        if (!newValue) {
          videoCall.value.duration = new Date("Tue Jun 14 2022 00:00:00 GMT-0300 (Hora padrão de Brasília)")
        }
      }
    );

    watch(
      () => homeCare.value.duration,
      (newValue, oldValue) => {
        if (!newValue) {
          homeCare.value.duration = new Date("Tue Jun 14 2022 00:00:00 GMT-0300 (Hora padrão de Brasília)")
        }
      }
    );

    watch(
      () => valueCheckBox.value,
      async (newValue, oldValue) => {
        const add = newValue.filter((a) => !oldValue.includes(a));
        const remove = oldValue.filter((a) => !newValue.includes(a));
        inputSearchTuss.value = ""
        inputSearchSus.value = ""
        sus.value = []
        tuss.value = []
        isTussSelected.value = {
          type: null,
          data: { tuss: undefined, tussterm: undefined },
        };
        isSusSelected.value = {
          type: null,
          data: { sus: undefined, susprocedure: undefined },
        };


        if (add.length > 0) {
          console.log("add", add);
          console.log(add[0].type);
        } else {
          if (remove.length > 0) {
            console.log("remove", remove);
            console.log(remove[0].type);
            if (remove[0].type === "speciality") {
              const clearCheck = valueCheckBox.value.filter(
                (item) => item.specialityId !== remove[0].specialityId
              );
              valueCheckBox.value = clearCheck;
            }
          }
        }

        const arrNames = destructName();
        await tussSugestionList(arrNames);
      }
    );

    onMounted(async () => {
      await professionList();
    });

    return {
      tuss,
      sus,
      inputSearchTuss,
      inputSearchSus,
      isTussSelected,
      isSusSelected,
      listSearchTussItems,
      listSearchSusItems,
      councilId,
      nameService,
      listNameService,
      valueRadio,
      valueCheckBox,
      description,
      medicalReturn,
      inPerson,
      videoCall,
      homeCare,
      close,
      onUpdate,
      onSubmit,
      isSpeciality,
      completeSearchTuss,
      completeSearchSus,
      selectedSearchTuss,
      selectedSearchSus,
      selectSusFromList,
      nameServiceIsSelected,
      selectTussFromList,
      clearTuss,
      clearSus,
      isMedical,
      correctFields,
      validation,
      validateField,
      getPerson,
      getVideoCall,
      getHomeCare,
      msgErros,
      valideDuration,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 1.5rem;
}

.box-tuss,
.box-sus {
  padding: 15px;
  background: #fafafa;
  border-radius: 10px;

  .box-sugestions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    margin-top: 8px;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e0e0e0;
      border-radius: 25px;
    }

    &::-webkit-scrollbar-track {
      margin: 10px;
    }

    .item {
      display: flex;
      align-items: center;
      padding: 10px 12px;
      width: 100%;
      height: max-content;
      color: #333333;
      background: white;
      border-radius: 8px;
      border: 1px solid #ced4da;
      cursor: pointer;

      &:hover {
        border-color: #ff4500;
      }
    }
  }
}

.text-label {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: #4f4f4f;
}

.tag-color {
  width: 4px;
  height: 16px;
  background: #a2c0d4;
  border-radius: 2px;
}

.vertical {
  position: absolute;
  background: #bdbdbd;
  width: 1px !important;
  left: 7px;
  top: 20px;
  height: calc(100% - 27px) !important;
}

.horizontal {
  position: absolute;
  background: #bdbdbd;
  left: -12px;
  top: 8px;
  width: 8px !important;
  height: 1px !important;
}

.txt-area {
  font-size: 1rem;
  font-weight: normal;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  resize: none;
  margin-bottom: 0 !important;

  &:disabled {
    background: #e0e0e0;
    color: #b9bdbf;
    border: 1px solid#e0e0e0;

    &::placeholder {
      color: #b9bdbf;
    }

    &:hover {
      border-color: #e0e0e0;
    }
  }
}

.mtop-8 {
  margin-top: 8px !important;
}

.mleft-8 {
  margin-left: 8px !important;
}

.mleft-20 {
  margin-left: 20px !important;
}

.btn-active {
  background: linear-gradient(177.46deg, #FF6A33 -9.25%, #FF4500 62.16%, #E53E00 158.71%);
  box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);
  color: white;
  transition: none;

  &:hover {
    color: white !important;
    background: #f5835a !important;
  }
}

::v-deep(button) {
  background: #e0e0e0;
  color: #828282;
  border: none;
  height: 40px;

  &:hover {
    background: #e0e0e0 !important;
    color: #828282 !important;
    border: none;
  }
}

::v-deep(input) {
  max-width: 100%;
  height: 45px;
  border-radius: 8px;
  color: #2d313d !important;
  font-weight: 400 !important;
}

::v-deep(textarea) {
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding: 1rem;
  font-size: 14px;
  width: 100%;
  outline: none;
  color: #2d313d !important;

  &:focus {
    border-color: #ff6a33;
  }

  &:hover {
    outline: none;
    border-color: #ff6a33;
  }
}

::v-deep(.txt-right) {
  input {
    text-align: right !important;
  }
}

::v-deep(.field-checkbox) {
  margin-bottom: 8px;
}

::v-deep(.p-dropdown) {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

::v-deep(.p-autocomplete) {
  width: 100%;

  input {
    border-top: 1px solid #ced4da;
  }
}

::v-deep(.item-active) {
  input {
    border-color: #ff6a33 !important;
    border: 1px solid #ff6a33 !important;
  }
}
</style>
