<template>
  <div class="card-profission">
    <div class="profission" v-for:="concil_id in councilprofessionalsList" style="position: relative">
      <div class="vertical" />
      <div class="field-checkbox">
        <RadioButton name="council" :value="{ councilId: concil_id.councilid }" v-model="valueRadio"
          :disabled="type === 'View'" />
        <label for="binary" class="text-bold">
          {{ concil_id.council && concil_id.council }} -
          {{ concil_id.state && concil_id.state }}
        </label>
      </div>
      <div class="mleft-20" style="position: relative" v-for:="speciality in concil_id.specialityList"
        v-if="valueRadio.councilId === concil_id.councilid">
        <div class="vertical" style="background: #A2C0D4;" />
        <!-- <div class="horizontal" /> -->
        <div class="field-checkbox">
          <Checkbox id="binary" v-model="valueCheckBox" :value="{
            councilId: concil_id.councilid,
            specialityId: speciality.specialtyid,
            name: speciality.description,
            type: 'speciality',
          }" :trueValue="true" :falseValue="false"
            :disabled="type === 'View' || valueRadio.councilId !== concil_id.councilid" />
          <label for="binary">{{ speciality.description }}</label>
        </div>
        <div class="mleft-20" style="position: relative" v-for:="actuation in speciality.actuationList" v-if="valueRadio.councilId === concil_id.councilid && !checkSpeciality({
          councilId: concil_id.councilid,
          specialityId: speciality.specialtyid,
          name: speciality.description,
          type: 'speciality',
        })">
          <!-- <div class="horizontal" /> -->
          <div class="field-checkbox check-blue">
            <Checkbox id="binary" v-model="valueCheckBox" :value="{
              councilId: concil_id.councilid,
              specialityId: speciality.specialtyid,
              actionActuationId: actuation.actionactuationid,
              name: actuation.description,
              type: 'actuation',
            }" :disabled="type === 'View'" />
            <label for="binary">{{ actuation.description }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
// import profileService from "../../../core/services/professionalProfileService";
import tussService from "../../../core/services/tussService";
import {
  ListTussSuggestionsRequest,
} from "../../../core/grpc/generated/tuss_pb";

export default {
  props: [
    "councilprofessionalsList",
    "specialitiesList",
    "nameService",
    "suggestionTuss",
    "isMedical",
    "type"
  ],
  emits: ["clearfull", "update:specialitiesList", "update:isMedical", "update:suggestionTuss"],
  setup(props, { emit }) {
    const mounted = ref(false)
    const valueRadio = ref({ councilId: null });
    const valueCheckBox = ref([]);
    const defaultRegister = ref({});

    const nameServiceIsSelected = () => {
      if (props.nameService) {
        return true;
      }
      return false;
    };

    const isMedical = () => {
      const res = props.councilprofessionalsList.find(item => valueRadio.value.councilId === item.councilid)

      if (res?.council === "Médico(a)") {
        return true
      }
      return false
    }

    const tussSugestionList = async (listSpecialty) => {
      const req = new ListTussSuggestionsRequest();
      req.setSpecialtyList(listSpecialty);
      const res = await tussService.listTussSuggestion(req);

      emit("update:suggestionTuss", res.correlationsList)
    };

    const destructName = () => {
      const names = [];
      valueCheckBox.value.map((item) => names.push(item.name));
      if (names.length > 0) {
        return names;
      }
      return [""];
    };

    const checkSpeciality = obj => {
      const bool = valueCheckBox.value.find(item => (item.councilId === obj.councilId) && (item.name === obj.name))

      if (bool) {
        return false
      }
      return true
    }

    watch(() => valueRadio.value,
      (newValue, oldValue) => {
        if (mounted.value) {
          valueCheckBox.value = []
          emit("clearfull", true)
          emit("update:specialitiesList", newValue)
        }
        emit("update:isMedical", isMedical())
      });

    watch(() => valueCheckBox.value,
      async (newValue, oldValue) => {
        if (newValue) {
          let arr = [];
          let add = newValue.filter((a) => !oldValue.includes(a)) // se adicionou
          let remove = oldValue.filter((a) => !newValue.includes(a)) // se removeu
          const orderList = { // listagem de ordem 
            actuation: 1,
            speciality: 2
          }

          // console.log('Primeira Marcacao', defaultRegister.value) // primeiro registro de marcações

          if (remove.length > 0) {
            //função para ao desmarcar uma especialidade a atuação sai
            if (remove[0].type === "speciality") { // removeu uma specialidade -- OK
              let removeChild = newValue.filter(item => item.specialityId !== remove[0].specialityId).sort((a, b) => orderList[a.type] - orderList[b.type])
              valueCheckBox.value = removeChild

              //Função para adicionar atuação e se já existir não adiciona a especialidade
              removeChild.map((item) => {
                if (item.actionActuationId) {
                  //adiciona uma atuação
                  // console.log('actuation aq', item)
                  arr.push({
                    councilId: item.councilId,
                    specialityId: item.specialityId,
                    actionActuationId: item.actionActuationId,
                    name: item.description,
                    type: "actuation",
                  });
                } else {
                  const exist = arr.find((element) => element.specialityId === item.specialityId);
                  if (!exist) {
                    //se ja existir uma especialidade marcada não duplica
                    arr.push({
                      councilId: item.councilId,
                      specialityId: item.specialityId,
                      name: item.name,
                      type: "speciality",
                    });
                  }
                }
              });

              // se for médico sugere o tuss
              if (isMedical()) {
                await tussSugestionList(destructName());
              }

              // se tiver algum checkbox marcado ele envia, se não ele envia só o council para não ficar vázio.
              if (mounted.value) {
                if (arr.length > 0) {
                  emit("update:specialitiesList", arr)
                } else {
                  emit("update:specialitiesList", valueRadio.value);
                }
              }
            } else {
              // envia a atuação
              emit("update:specialitiesList", newValue)
            }
          } else {
            // se adicionou algum checkbox
            if (add.length > 0) {
              const list = {
                actuation: 1,
                speciality: 2
              }
              const valueCheck = newValue.sort((a, b) => list[a.type] - list[b.type])
              const service_has_specialities = [];
              valueCheck.map((item) => {
                if (item.actionActuationId) {
                  service_has_specialities.push({
                    councilId: item.councilId,
                    specialityId: item.specialityId,
                    actionActuationId: item.actionActuationId,
                    name: item.name,
                  });
                } else {
                  const exist = service_has_specialities.find((element) => element.specialityId === item.specialityId);
                  if (!exist) {
                    service_has_specialities.push({
                      councilId: item.councilId,
                      specialityId: item.specialityId,
                      name: item.name,
                    });
                  }
                }
              });

              if (service_has_specialities.length === 0) {
                service_has_specialities.push(valueRadio.value)
              }

              arr = service_has_specialities

              if (isMedical()) {
                await tussSugestionList(destructName());
              }
              if (mounted.value) {
                if (arr.length > 0) {
                  emit("update:specialitiesList", arr);
                } else {
                  emit("update:specialitiesList", valueRadio.value)
                }
              }
            }
          }
        }
      });

    onMounted(async () => {
      const arr = []
      const council = await props.specialitiesList.find(item => item.profcouncilid && !item.specialityid && !item.actionactuationid);
      const speciality = await props.specialitiesList.filter(item => item.profcouncilid && item.specialityid && !item.actionactuationid);
      const actuation = await props.specialitiesList.filter(item => item.profcouncilid && item.specialityid && item.actionactuationid);

      // console.log(props.specialitiesList)
      // console.log(props.councilprofessionalsList)

      defaultRegister.value = props.specialitiesList



      if (council) {
        valueRadio.value = { councilId: council.profcouncilid }
      } else {
        if (speciality.length > 0) {
          valueRadio.value = { councilId: speciality[0].profcouncilid }
        } else if (actuation.length > 0) {
          valueRadio.value = { councilId: actuation[0].profcouncilid }
        }
      }

      if (speciality) {
        await speciality.map(item => {
          arr.push({
            councilId: item.profcouncilid,
            specialityId: item.specialityid,
            name: item.descriptionspeciality,
            type: 'speciality'
          })
        })
      }
      if (actuation) {
        await actuation.map(item => {
          arr.push({
            councilId: item.profcouncilid,
            specialityId: item.specialityid,
            actionActuationId: item.actionactuationid,
            name: item.descriptionactionactuation,
            type: 'actuation'
          })
          arr.push({
            councilId: item.profcouncilid,
            specialityId: item.specialityid,
            name: item.descriptionspeciality,
            type: 'speciality'
          })
        })
      }
      valueCheckBox.value = arr;
      mounted.value = await true
    })

    return { valueRadio, valueCheckBox, nameServiceIsSelected, checkSpeciality };
  },
};
</script>

<style lang="scss" scoped>
.card-profission {
  padding: 17px;
  background: #FAFAFA;
  border-radius: 8px;
  .profission:last-child{
    .field-checkbox{
      margin-bottom: 0px;
    }
  }
}

.vertical {
  position: absolute;
  background: #FF6A33;
  width: 2px !important;
  left: 7px;
  top: 20px;
  height: calc(100% - 18px) !important;
}

.mtop-8 {
  margin-top: 8px !important;
}

.mleft-20 {
  margin-left: 20px !important;
}

.check-blue {
  ::v-deep(.p-checkbox-box) {
    border: 2px solid #A2C0D4 !important;
  }

  ::v-deep(.p-checkbox-checked) {
    .p-checkbox-box {
      background: #A2C0D4 !important;
    }
  }
}

::v-deep(.p-checkbox-icon) {
  transform: scale(.8);
  margin-left: 1px;
  margin-top: 1px;
}

::v-deep(.p-radiobutton-box) {
  border: 2px solid #BDBDBD;
}

::v-deep(.p-radiobutton-icon) {
  background: #FAFAFA !important;
}

::v-deep(.p-radiobutton-checked) {

  .p-radiobutton,
  .p-radiobutton-box,
  .p-highlight {
    background: #FF6A33 !important;
  }
}
</style>
