<template>
    <div class="dialog-addservice-homecare" :class="[errorAll, borderError]">
        <div class="header">
            <div class="title">
                <div class="tag" />
                <b>Homecare</b>
            </div>
            <div class="flex align-items-center" :class="disabled && 'cursor-not-allowed'">
                <InputSwitch v-model="bool" :disabled="disabled" />
            </div>
        </div>
        <div v-if="bool" class="flex flex-column mt-3">
            <div class="flex">
                <div class="flex flex-column w-6 pr-2">
                    <label for="duration" class="txt-title"> Duração </label>
                    <Duration v-model="duration" :disabled="disabled" />
                </div>
                <div class="flex flex-column w-6 pl-2">
                    <label for="price" class="txt-title"> Valor </label>
                    <InputNumber 
                        class="txt-right" 
                        :class="(error.currency && !currency) && 'p-invalid'"
                        v-model="currency" 
                        mode="currency" 
                        currency="BRL" 
                        locale="pt-BR" 
                        :disabled="disabled" 
                    />
                    <p class="p-invalid" v-if="error.currency && !currency">{{ error.currency }}</p>
                </div>
            </div>
            <div>
                <div class="my-2">
                <span class="deslocamento">Deslocamento</span>
                </div>
                <div class="flex">
                <div class="flex flex-column w-6 pr-2">
                    <label for="price" class="txt-title">
                    Valor por Km
                    </label>
                    <InputNumber id="expiry" mode="currency" currency="BRL" locale="pt-BR" class="txt-right" v-model="km"
                    suffix=" / Km" :disabled="disabled" />
                </div>
                <div class="flex flex-column w-6 pl-2">
                    <label for="price" class="txt-title">
                    Raio de abrangência
                    </label>
                    <InputNumber 
                        id="expiry" 
                        class="txt-right" 
                        v-model="raio" 
                        suffix=" Km" 
                        :disabled="disabled" 
                        :class="(error.raio && !raio) && 'p-invalid'"
                    />
                    <p class="p-invalid" v-if="error.raio && !raio">{{ error.raio }}</p>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, watch, computed } from "vue";
import Duration from "../../../../components/Duration/Duration.vue";

export default {
    props: ["homeCare", "disabled", "errorAll", "error"],
    emits: ["update:homeCare"],
    components: {
        Duration,
    },
    setup(props, { emit }) {
        const bool = ref(false);
        const duration = ref(0);
        const currency = ref(0);
        const km = ref(0);
        const raio = ref(0);
        const borderError = computed(() => {
            if((props.error.currency || props.error.raio) && (!currency.value || !raio.value)){
                return "border-error";
            } 
            return ''
        });

        const getObj = async () => {
            return await {
                active: bool.value,
                duration: duration.value,
                value: currency.value,
                km: km.value,
                raio: raio.value,
            }
        }

        watch( () => bool.value, async (newValue, oldValue) => {
            emit("update:homeCare", await getObj());
        });
        
        watch( () => duration.value, async(newValue, oldValue) => {
            if (newValue || newValue === 0) {
                emit("update:homeCare", await getObj());
            }
        });
        
        watch( () => currency.value, async (newValue, oldValue) => {
            if (newValue || newValue === 0) {
                emit("update:homeCare", await getObj());
            }
        });  
        
        watch( () => raio.value, async (newValue, oldValue) => {
            if (newValue || newValue === 0) {
                emit("update:homeCare", await getObj());
            }
        });  
        
        watch( () => km.value, async (newValue, oldValue) => {
            if (newValue || newValue === 0) {
                emit("update:homeCare", await getObj());
            }
        });  

        onMounted(() => {
            if(props.homeCare.active){
                bool.value = props.homeCare.active;
            }
            if(props.homeCare.duration){
                duration.value = props.homeCare.duration;
            }
            if(props.homeCare.value){
                currency.value = props.homeCare.value;
            }
            if(props.homeCare.km){
                km.value = props.homeCare.km;
            }
            if(props.homeCare.raio){
                raio.value = props.homeCare.raio;
            }
        })

        return {
            bool,
            duration,
            currency,
            km,
            raio,
            borderError
        }
    },
};
</script>


<style lang="scss" scoped>
.dialog-addservice-homecare {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 13px 16px;
    border: 1px solid #F2F2F2;
    border-radius: 8px;

    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title{
        display: flex;
        align-items: center;
        gap: 10px;
        b{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #4F4F4F;
        }
    }
    .tag{
        width: 18px;
        height: 16px;
        background: #FF6A33;
        border-radius: 4px;
    }

    .txt-title{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #828282;
    }
    .deslocamento{
        font-family: 'Roboto';
        font-style: normal;
        font-size: 12px;
        line-height: 16px;
    }
}

.border-error{
    border: 1px solid red;
}

::v-deep(input) {
    border-radius: 8px;
    text-align: right;
}
</style>