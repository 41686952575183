<template>
    <div class="dialog-addservice-videocall" :class="[errorAll, borderError]">
        <div class="header">
            <div class="title">
                <div class="tag" />
                <b>Videochamada</b>
            </div>
            <div class="flex align-items-center" :class="disabled && 'cursor-not-allowed'">
                <InputSwitch v-model="bool" :disabled="disabled" />
            </div>
        </div>
        <div v-if="bool" class="flex mt-3">
            <div class="flex flex-column w-6 pr-2">
                <label for="duration" class="txt-title"> Duração </label>
                <Duration v-model="duration" :disabled="disabled" :class="(error.duration && !duration) && 'p-invalid'" />
                <p class="p-invalid" v-if="error.duration && !duration">{{ error.duration }}</p>
            </div>
            <div class="flex flex-column w-6 pl-2">
                <label for="price" class="txt-title"> Valor </label>
                <InputNumber class="txt-right" v-model="currency" mode="currency" currency="BRL" locale="pt-BR" :disabled="disabled" :class="(error.currency && !currency) && 'p-invalid'" />
                <p class="p-invalid" v-if="error.currency && !currency">{{ error.currency }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref, watch, computed } from "vue";
import Duration from "../../../../components/Duration/Duration.vue";

export default {
    props: ["videoCall", "disabled", "error", "errorAll"],
    emits: ["update:videoCall"],
    components: {
        Duration,
    },
    setup(props, { emit }) {
        const bool = ref(false);
        const duration = ref(0);
        const currency = ref(0);
        const borderError = computed(() => {
            if((props.error.duration || props.error.currency) && (!duration.value || !currency.value)){
                return "border-error";
            } 
            return ''
        });

        const getObj = async () => {
            return await {
                active: bool.value,
                duration: duration.value,
                value: currency.value,
            }
        }

        watch( () => bool.value, async (newValue, oldValue) => {
            emit("update:videoCall", await getObj());
        });
        
        watch( () => duration.value, async(newValue, oldValue) => {
            if (newValue || newValue === 0) {
                emit("update:videoCall", await getObj());
            }
        });
        
        watch( () => currency.value, async (newValue, oldValue) => {
            if (newValue || newValue === 0) {
                emit("update:videoCall", await getObj());
            }
        });   

        onMounted(() => {
            if(props.videoCall.active){
                bool.value = props.videoCall.active;
            }
            if(props.videoCall.duration){
                duration.value = props.videoCall.duration;
            }
            if(props.videoCall.value){
                currency.value = props.videoCall.value;
            }
        })

        return {
            bool,
            duration,
            currency,
            borderError
        }
    },
};
</script>


<style lang="scss" scoped>
.dialog-addservice-videocall {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 13px 16px;
    border: 1px solid #F2F2F2;
    border-radius: 8px;
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .title{
        display: flex;
        align-items: center;
        gap: 10px;
        b{
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            color: #4F4F4F;
        }
    }
    .tag{
        width: 18px;
        height: 16px;
        background: #2D313D;
        border-radius: 4px;
    }

    .txt-title{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #828282;
    }
}
.border-error{
    border: 1px solid red;
}
::v-deep(input) {
    border-radius: 8px;
    text-align: right;
}
</style>