<template>
  <div class="card-videocall flex flex-column" :style="type === 'View' && 'opacity: .6'">
    <div class="flex justify-content-between align-items-center">
      <div class="flex align-items-center">
        <div class="tag-color" style="background: #2d313d" />
        <span class="text-label ml-10">Videochamada</span>
      </div>
      <InputSwitch v-model="active" :disabled="type === 'View'" />
    </div>
    <div v-if="active" class="d-flex mt-3">
      <div class="flex flex-column w-6 pr-2">
        <label for="duration" class="text-bold span-color fs-2 mb-1"> Duração </label>
        <Duration v-model="duration" :disabled="type === 'View'" />
        <p class="p-invalid">{{ msgErros.Videochamada.duration }}</p>
      </div>
      <div class="flex flex-column w-6 pl-2">
        <label for="price" class="text-bold span-color fs-2 mb-1"> Valor </label>
        <InputNumber class="txt-right" v-model="currency" mode="currency" currency="BRL" locale="pt-BR"
          :disabled="type === 'View'" :class="msgErros.Videochamada.currency && 'p-invalid'" />
        <p class="p-invalid">{{ msgErros.Videochamada.currency }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Duration from "../../../../components/Duration/Duration";
import { ref, onMounted, watch } from "vue";

export default {
  props: ["servicetypesList", "msgErros", "type"],
  emits: ["update:servicetypesList"],
  components: { Duration },
  setup(props, { emit }) {
    const mounted = ref(false)
    const id = ref("");
    const active = ref(false)
    const duration = ref(0)
    const currency = ref(0)

    const getVideoCall = (serviceTypes) => {
      const obj = serviceTypes.find((item) => item.servicemode === 'Videochamada');
      return obj
    };

    const getObj = () => {
      return {
        id: id.value,
        status: active.value ? "Ativo" : "Inativo",
        estimatedTime: duration.value,
        value: currency.value,
        servicemode: "Videochamada",
        valuekm: 0,
        valuepercentage: 0,
        maxDisplacement: 0,
      }
    }

    const getIndex = () => {
      const index = props.servicetypesList.findIndex((item) => item.servicemode === 'Videochamada')

      return index
    }

    watch(() => active.value,
    async (newValue) => {
      if (mounted.value) {
        const arr = props.servicetypesList
        const obj = getObj();
        const index = getIndex();

        if (index > -1) {
          arr[index] = obj
        } else {
          arr.push(obj)
        }
        emit("update:servicetypesList", arr)
      }
    })

    watch(() => duration.value,
    async (newValue) => {
      if (mounted.value) {
        const arr = props.servicetypesList
        const obj = getObj();
        const index = getIndex();

        if (index !== -1) {
          arr[index] = {...obj}
        } else {
          arr.push(obj)
        }
        emit("update:servicetypesList", arr)
      }
    })

    watch(() => currency.value,
    async (newValue) => {
      if (mounted.value) {
        const arr = props.servicetypesList
        const obj = getObj();
        const index = getIndex();

        if(!newValue){
          obj.value = 0
          currency.value = 0
        }

        if (index !== -1) {
          arr[index] = {...obj}
        } else {
          arr.push(obj)
        }
        emit("update:servicetypesList", arr)
      }
    })

    onMounted(async () => {
      const video_call = await getVideoCall(props.servicetypesList);

      if (video_call) {
        id.value = video_call.id
        active.value = video_call.status === 'Ativo' ? true : false
        duration.value = video_call.estimatedTime;
        
        currency.value = video_call.value
      }
      mounted.value = await true
    });

    return { active, duration, currency };
  },
};
</script>

<style lang="scss" scoped>
.card-videocall{
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #F2F2F2;
}
.tag-color {
  width: 16px;
  height: 16px;
  background: #a2c0d4;
  border-radius: 4px;
}

.text-label {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: #4f4f4f;
}

.mtop-8 {
  margin-top: 8px !important;
}

.mleft-8 {
  margin-left: 8px !important;
}

::v-deep(.txt-right) {
  input {
    text-align: right !important;
  }
}
</style>
