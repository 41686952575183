<template>
  <div class="content">
    <Skeleton width="100%" height="40px" borderRadius="8px" />
    <div class="box-profission mt-4">
      <Skeleton width="200px" height="12px" borderRadius="4px" />
      <div class="row mt-8">
        <Skeleton shape="circle" size="16px" />
        <Skeleton width="100px" height="12px" borderRadius="4px" />
      </div>
      <div class="row mt-8">
        <Skeleton shape="circle" size="16px" />
        <Skeleton width="100px" height="13px" borderRadius="4px" />
      </div>
    </div>

    
    <Skeleton class="mt-4" width="100%" height="40px" borderRadius="8px" />
  
    <Skeleton class="mt-4" width="100%" height="40px" borderRadius="8px" />

    <Skeleton class="mt-4" width="100%" height="120px" borderRadius="8px" />

    <div class="row-between">
      <div class="flex align-items-center">
        <Skeleton width="16px" height="16px" borderRadius="4px" />
        <Skeleton class="ml-8" width="110px" height="16px" borderRadius="4px" />
      </div>
      <Skeleton width="40px" height="24px" borderRadius="16px" />
    </div>
    <div class="row-between">
      <div class="flex align-items-center">
        <Skeleton width="16px" height="16px" borderRadius="4px" />
        <Skeleton class="ml-8" width="80px" height="16px" borderRadius="4px" />
      </div>
      <Skeleton width="40px" height="24px" borderRadius="16px" />
    </div>
    <div class="row-between">
      <div class="flex align-items-center">
        <Skeleton width="16px" height="16px" borderRadius="4px" />
        <Skeleton class="ml-8" width="100px" height="16px" borderRadius="4px" />
      </div>
      <Skeleton width="40px" height="24px" borderRadius="16px" />
    </div>
    <div class="row-between">
      <div class="flex align-items-center">
        <Skeleton width="16px" height="16px" borderRadius="4px" />
        <Skeleton class="ml-8" width="80px" height="16px" borderRadius="4px" />
      </div>
      <Skeleton width="40px" height="24px" borderRadius="16px" />
    </div>

  </div>
</template>

<script>
// import { ref, onMounted, watch } from "vue";

export default {
  setup(props, { emit }) {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 8px;
}

.ml-16 {
  margin-left: 19px;
}
.ml-6 {
  margin-left: 6px;
}

.row {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.vertical {
  position: absolute;
  background: #bdbdbd;
  width: 1px !important;
  left: 7px;
  top: 20px;
  height: calc(100% - 4px) !important;
}

.horizontal {
  position: absolute;
  background: #bdbdbd;
  left: -12px;
  top: 8px;
  width: 12px !important;
  height: 1px !important;
}
.box-profission {
  padding: 16px;
  background: #fafafa;
  border-radius: 10px;
}

.row-between{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #F2F2F2;
  padding: 12px 16px;
  border-radius: 8px;
}
</style>
