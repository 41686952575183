<template>
  <div class="card-homecare flex flex-column" :style="type === 'View' && 'opacity: .6'">
    <div class="flex justify-content-between align-items-center">
      <div class="flex align-items-center gap-10">
        <img src="../../../../assets/icons/myservices/return.png" alt="icon return medical">
        <span class="text-label">Direito a retorno</span>
      </div>
      <InputSwitch v-model="medicalReturn.active" :disabled="type === 'View'" />
    </div>
    <div v-if="medicalReturn.active" class="flex justify-content-between align-items-center mt-3">
      <label for="duration" class="fs-2 mb-1">
        Número máximo de dias para o retorno:
      </label>
      <InputNumber
        id="expiry"
        class="txt-right"
        placeholder="0 dia(s)"
        :style="{ width: '100px' }"
        v-model="medicalReturn.maxDays"
        suffix=" dia(s)"
        :disabled="type === 'View'"
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";

export default {
  props: ["pb_return", "time", "msgErros","type"],
  emits: ["update:pb_return", "update:time"],
  setup(props, {emit}) {
    const mounted = ref(false)
    const medicalReturn = ref({
      active: false,
      maxDays: 1,
    });

    const isActive = () => {
      if(props){
        return true
      }
      return false
    }

    const getReturn = (pb_return, time) => {    
      return {
        active: Boolean(pb_return),
        maxDays: time
      }
    };

    watch(() => medicalReturn.value.active,
    (newValue) => {
      if(mounted.value){
        const boolToNumber = newValue ? 1 : 0
        emit("update:pb_return", boolToNumber)
      }
    });

    watch(() => medicalReturn.value.maxDays,
    (newValue) => {
      if(mounted.value){
        if(!!newValue){
          emit("update:time", newValue)
        }else{
          medicalReturn.value.maxDays = 1
          emit("update:time", 1)
        }
      }
    });
    
    onMounted(async () => {
      const rMedical = await getReturn(props.pb_return, props.time);
      if(rMedical){
        medicalReturn.value = rMedical
      }
      mounted.value = await true
    });

    return { isActive, medicalReturn};
  },
};
</script>

<style lang="scss" scoped>
.card-homecare{
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #F2F2F2;
}

.text-label {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: #4f4f4f;
}
.mtop-8 {
  margin-top: 8px !important;
}
.mleft-8 {
  margin-left: 8px !important;
}
::v-deep(.txt-right) {
  input {
    text-align: right !important;
    width: 100px;
  }
}
</style>
