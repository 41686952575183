<template>
  <Dropdown
    v-model="modelValue"
    :options="listNameService"
    :value="modelValue"
    optionLabel="name"
    optionValue="name"
    placeholder="Nome do Serviço"
    :disabled="type === 'View'"
    @change="$emit('update:modelValue', $event.value)"
  />
</template>

<script>
export default {
  props: ["modelValue", "type"],
  emits: ["update:modelValue"],
  setup() {
    const listNameService = [
      { name: "Remoção", code: "" },
      { name: "Pequena Cirurgia", code: "" },
      { name: "Outras Terapias", code: "" },
      { name: "Consulta", code: "" },
      { name: "Exame Ambulatorial", code: "" },
      { name: "Atendimento Domiciliar", code: "" },
      { name: "Internação", code: "" },
      { name: "Quimioterapia", code: "" },
      { name: "Radioterapia", code: "" },
      { name: "Terapia Renal Substitutiva (TRS)", code: "" },
      { name: "Pronto Socorro", code: "" },
      { name: "Ocupacional", code: "" },
      { name: "Pequeno atendimento (sutura, gesso e outros)", code: "" },
      { name: "Saúde Ocupacional - Admissional", code: "" },
      { name: "Saúde Ocupacional - Demissional", code: "" },
      { name: "Saúde Ocupacional - Periódico", code: "" },
      { name: "Saúde Ocupacional - Retorno ao trabalho", code: "" },
      { name: "Saúde Ocupacional - Mudança de função", code: "" },
      { name: "Saúde Ocupacional - Promoção a saúde", code: "" },
      { name: "Saúde Ocupacional - Beneficiário novo", code: "" },
      { name: "Saúde Ocupacional - Assistência a demitidos", code: "" },
      { name: "TELESSAÚDE", code: "" },
    ];

    return { listNameService };
  },
};
</script>

<style lang="scss" scoped></style>
