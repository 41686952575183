<template>
    <Calendar 
        v-model="textValue" 
        :timeOnly="true" 
        :showTime="true" 
        :disabled="disabled" 
        @blur="changeValue"
        maxlength="5"
    />
</template>

<script>
import { onMounted, ref, watch } from "vue";

export default {
    props: ["modelValue", "output", "disabled"],
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const textValue = ref();

        const stringToDate = (str) => {
            const min = stringToMinutes(str);
            const date = new Date();
            const hour = parseInt(minutesToString(min, "hours"));
            const minutes = parseInt(minutesToString(min, "minutes"));
            date.setHours(hour, minutes);

            // console.log('hour:' + hour)
            // console.log('minutes:' + minutes)
            // console.log('strDate: saida - '+ date);
            
            return date;
        }

        const stringToMinutes = (str) => {
            const date = new Date();
            const hour = parseInt(str[0] + str[1]);
            const minutes = parseInt(str[3] + str[4]);
            date.setHours(hour, minutes);

            return date.getHours() * 60 + date.getMinutes();
        }

        const minutesToString = (minutes, type) => {
            if (minutes) {
                var sec_num = parseInt(minutes);
                var hours = Math.floor(sec_num / 3600);
                var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
                var seconds = sec_num - (hours * 3600) - (minutes * 60);

                if (minutes < 10) { minutes = "0" + minutes; }
                if (seconds < 10) { seconds = "0" + seconds; }
                if(type === "hours"){
                    return minutes;
                } 
                if(type === "minutes"){
                    return seconds
                }
                return minutes + ':' + seconds;

            }
            return "00:00"
        }

        const validationToDateString = (str) => {
            const array = str.split("");
            let validationsStatus = true;

            if(array.length < 5){
                validationsStatus = false;
            }

            array.map((value, index) => {
                let validationNow = true;
                if(index === 0 || index === 1  || index === 3 || index === 4){
                    for (let i = 0; i <= 9; i++) {
                        if(!value === String(i)){
                            validationNow = false;
                        }
                    }
                }
                if(index === 2){
                    if(value !== ":"){
                        validationNow = false;
                    }
                }
                if(!validationNow){ validationsStatus = false; }
            })

            return validationsStatus;
        }

        const changeValue = ({ target }) => {
            if(target.value){
                if(validationToDateString(target.value)){
                    const minutes = stringToMinutes(target.value);
                    // emit("update:modelValue", minutes);
                    if(!props.output || props.output === 'number'){
                        emit("update:modelValue", minutes);
                    }else{
                        if(props.output === 'string'){
                            const str = target.value;
                            emit("update:modelValue", str);
                        }
                    }
                }                
            }
        }
        
        onMounted(async () => {
            // console.log('duration:' + props.modelValue);
            if(props.modelValue){
                if(typeof props.modelValue === 'number'){
                    const date = await stringToDate(minutesToString(props.modelValue))
                    textValue.value = date;
                }else{
                    if(typeof props.modelValue === 'string'){
                        const date = await stringToDate(props.modelValue)
                        textValue.value = date;
                    }
                }
            }else{
                const date = await stringToDate(minutesToString(0))
                textValue.value = date;
            }
        })

        watch( () => textValue.value, (newValue, oldValue) => {
            if(!newValue){
                textValue.value = oldValue;
            }
        });

        return {
            textValue,
            changeValue,
        }
    },
};
</script>


<style lang="scss" scoped>


::v-deep(input) {
    border-radius: 8px;
    text-align: right;
}
</style>