<template>
  <div>
    <DialogDiscardChanges v-model:visible="discardChanges" @closeall="close" />
    <Dialog class="modal" :style="{
      width: '402px',
      borderRadius: '8px',
    }" :visible="visible" @hide="close" @update:visible="onUpdate">
      <template #header>
        <h5 class="m-auto">{{ getType() }}</h5>
      </template>
      <Loader v-if="isLoading" />
      <div class="content" v-else>
        <NameService v-model="dataService.name" :type="type" />

        <Profission :councilprofessionalsList="councilprofessionalsList"
          v-model:specialitiesList="dataService.specialitiesList" :nameService="dataService.name"
          v-model:suggestionTuss="suggestionTuss" v-model:isMedical="isMedical" :type="type"
          @clearfull="clearTussSusDescription" />

        <Tuss :nameService="dataService.name" v-model:tuss="dataService.tuss" v-model:tussterm="dataService.tussterm"
          v-model:sus="dataService.sus" v-model:susprocedure="dataService.susprocedure"
          v-model:description="dataService.description" :suggestionTuss="suggestionTuss"
          v-model:suggestionSus="suggestionTuss" :type="type" v-if="isMedical" />

        <Sus :nameService="dataService.name" :tuss="dataService.tuss" :tussterm="dataService.tussterm"
          v-model:sus="dataService.sus" v-model:susprocedure="dataService.susprocedure" :suggestionSus="suggestionSus"
          v-model:description="dataService.description" :type="type" v-if="isMedical" />
        <p class="p-invalid" v-if="validation && isMedical && !(dataService.tuss || dataService.sus)">{{ msgErros.tuss }}
        </p>

        <Textarea class="mtop-8" placeholder="Descrição" rows="5" cols="30" v-model="dataService.description"
          :autoResize="true" :disabled="type === 'View'" />
        <p class="p-invalid" v-if="validation && msgErros.description && !dataService.description">
          {{ msgErros.description }}</p>

        <ReturnMedical v-model:pb_return="dataService.pb_return" v-model:time="dataService.timeReturn" :msgErros="msgErros" :type="type" />
        <InPerson v-model:servicetypesList="dataService.servicetypesList" :msgErros="msgErros" :type="type" />
        <VideoCall v-model:servicetypesList="dataService.servicetypesList" :msgErros="msgErros" :type="type" />
        <HomeCare v-model:servicetypesList="dataService.servicetypesList" :msgErros="msgErros" :type="type" />
        <p class="p-invalid" v-if="validation && msgErros.professionalServiceTypes && errorValidationTypes()">
          {{ msgErros.professionalServiceTypes }}</p>
      </div>

      <template #footer>
        <div class="flex justify-content-end align-items-center h-full">
          <Button class="addService-button" :class="((!mounted || boolChanges()) && type !== 'View') && 'inative'" :label="getBtnSubmit()"
            @click="onSubmit" />
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import profileService from "../../../core/services/professionalProfileService";
import tussService from "../../../core/services/tussService";
import {
  ListActiveRegistriesRequest,
  ListServiceIdRequest,
  UpdateServiceRequest
} from "../../../core/grpc/generated/professionalProfile_pb.js";
import {
  ListSusCorrelatedToTussRequest,
} from "../../../core/grpc/generated/tuss_pb";
import NameService from "./NameService";
import Profission from "./Profission";
import Tuss from "./Tuss";
import Sus from "./Sus";
import ReturnMedical from "./ReturnMedical";
import InPerson from "./InPerson";
import VideoCall from "./Videocall";
import HomeCare from "./HomeCare";
import Loader from "./Loader";
import DialogDiscardChanges from "./DialogDiscardChanges";

export default {
  props: ["visible", "type", "id"],
  emits: ["update:visible", "update:type", "update:data", "refreshList"],
  components: {
    NameService,
    Profission,
    ReturnMedical,
    InPerson,
    VideoCall,
    HomeCare,
    Tuss,
    Sus,
    Loader,
    DialogDiscardChanges
  },
  setup(props, ctx) {
    const validation = ref(false);
    const mounted = ref(false);
    const isLoading = ref(true);
    const dataDefault = ref({});
    const discardChanges = ref(false);
    const dataService = ref({
      id: null,
      name: "",
      description: "",
      status: false,
      tuss: "",
      tussterm: "",
      sus: "",
      susprocedure: "",
      pb_return: 0,
      timeReturn: 0,
      specialitiesList: [],
      servicetypesList: [],
    });
    const suggestionTuss = ref([]);
    const suggestionSus = ref([]);
    const councilprofessionalsList = ref([]);
    const isMedical = ref(false);
    const arrServiceTypes = ref([])

    const msgErros = ref({
      name: null,
      professionalServiceTypes: null,
      description: null,
      Presencial: { duration: null, currency: null },
      Videochamada: { duration: null, currency: null },
      Homecare: { duration: null, currency: null, km: null },
    })

    const close = () => ctx.emit("update:visible", false)

    const onUpdate = (val) => {
      if (boolChanges() || !mounted.value) {
        (!val) && ctx.emit("update:visible", val);
      } else {
        discardChanges.value = true
      }
    }

    const getInfoCouncil = council => {
      const info = councilprofessionalsList.value.find(element => element.councilid === council)

      return info
    }

    const getInfoSpeciality = (listCouncil, speciality) => {
      const info = listCouncil.find(element => element.specialtyid === speciality)

      return info
    }

    const getInfoActuation = (listSpeciality, actuation) => {
      const info = listSpeciality.find(element => element.actionactuationid === actuation)

      return info
    }

    const checkDefault = (id, type) => {
      const info = dataDefault.value.specialitiesList.find(item => item[type] === id);
      return info
    }

    const clearErros = () => {
      msgErros.value = {
        name: null,
        professionalServiceTypes: null,
        description: null,
        Presencial: { duration: null, currency: null },
        Videochamada: { duration: null, currency: null },
        Homecare: { duration: null, currency: null, km: null },
      }
    }

    const UpdateService = async obj => {
      try {
        console.log('editar: ', obj)
        const req = new UpdateServiceRequest();
        req.setServiceid(obj.id);
        req.setName(obj.name)
        req.setTuss(obj.tuss)
        req.setTussterm(obj.tussterm)
        req.setSus(obj.sus)
        req.setSusprocedure(obj.susprocedure)
        req.setDescription(obj.description)
        req.setReturn(obj.pb_return)
        req.setTimeReturn(obj.timeReturn)

        // Validation Types
        if (obj.servicetypesList.length > 0) {
          obj.servicetypesList.forEach((element) => {
            // console.log(element)
            var serviceTypes = new proto.professionalProfileApi.UpdateProfessionalServiceTypes();
            if (element.id) {
              serviceTypes.setId(element.id);
            }
            if (element.servicemode) {
              serviceTypes.setServicemode(element.servicemode);
            }
            if (element.status) {
              serviceTypes.setStatus(element.status);
            }
            if (element.estimatedTime) {
              serviceTypes.setEstimatedTime(element.estimatedTime);
            }
            if (element.value) {
              serviceTypes.setValue(element.value);
            }
            if (element.valuekm) {
              serviceTypes.setValuekm(element.valuekm);
            }
            if (element.maxDisplacement) {
              serviceTypes.setMaxDisplacement(element.maxDisplacement);
            }

            req.addProfessionalServiceTypes(serviceTypes);
          })
        }

        // Validation Specialities
        if (obj.specialitiesList.length > 0) {
          obj.specialitiesList.forEach((element) => {
            // console.log(element)
            var specialities = new proto.professionalProfileApi.UpdateServiceHasSpecialities();
            if (element.id) {
              specialities.setId(element.id);
            }
            if (element.profcouncilid) {
              specialities.setCouncilid(element.profcouncilid);
            }
            if (element.specialityid) {
              specialities.setSpecialityid(element.specialityid);
            }
            if (element.actionactuationid) {
              specialities.setActionactuationid(element.actionactuationid);
            }
            req.addServiceHasSpecialities(specialities);
          })
        }

        const res = await profileService.updateService(req)
        const { success } = res

        if (success) {
          ctx.emit("refreshList", true)
          ctx.emit("update:visible", false)
        } else {
          const data = JSON.parse(res.data)
          const arrType = []
          clearErros()
          console.log(data);
          obj.servicetypesList.map(item => {
            arrType.push(item.servicemode)
          })

          arrServiceTypes.value = arrType

          for (const property in data) {
            const msg = data[property][0]
            switch (property) {
              case 'professionalServiceTypes[0].Value':
                msgErros.value[arrServiceTypes.value[0]].currency = msg
                break;
              case 'professionalServiceTypes[0].EstimatedTime':
                msgErros.value[arrServiceTypes.value[0]].duration = msg
                break;
              case 'professionalServiceTypes[0].MaxDisplacement':
                msgErros.value[arrServiceTypes.value[0]].raio = msg
                break;
              case 'professionalServiceTypes[1].Value':
                msgErros.value[arrServiceTypes.value[1]].currency = msg
                break;
              case 'professionalServiceTypes[1].EstimatedTime':
                msgErros.value[arrServiceTypes.value[1]].duration = msg
                break;
              case 'professionalServiceTypes[1].MaxDisplacement':
                msgErros.value[arrServiceTypes.value[1]].raio = msg
                break;
              case 'professionalServiceTypes[2].Value':
                msgErros.value[arrServiceTypes.value[2]].currency = msg
                break;
              case 'professionalServiceTypes[2].EstimatedTime':
                msgErros.value[arrServiceTypes.value[2]].duration = msg
                break;
              case 'professionalServiceTypes[2].MaxDisplacement':
                msgErros.value[arrServiceTypes.value[2]].raio = msg
                break;
              case 'professionalServiceTypes':
                msgErros.value.professionalServiceTypes = msg
                break;
              default:
                msgErros.value[property] = msg
            }
          }
          validation.value = true
          console.log(msgErros.value)
        }
      } catch (error) {
        console.log(error)
      }
    }

    const returnSpecialities = () => {
      const arr = []

      const specialities = dataService.value.specialitiesList
      if (specialities.length > 0) {
        specialities.map(item => {
          if (item.hasOwnProperty('councilId') && item.hasOwnProperty('specialityId') && !item.hasOwnProperty('actionActuationId')) {
            let id = ""
            const infoCouncil = getInfoCouncil(item.councilId)
            const defaultCouncil = checkDefault(item.councilId, "profcouncilid")
            const defaultSpeciality = checkDefault(item.specialityId, "specialityid")
            const defaultActuation = checkDefault(item.actionActuationId, "actionactuationid")

            if (defaultCouncil && !defaultSpeciality && !defaultActuation) {
              const size = specialities.filter(el => el.councilId === item.councilId).length
              // console.log(item.name, size)

              if (size === 1) {
                id = defaultCouncil.id
              }
            }

            if (defaultCouncil && defaultSpeciality && !defaultActuation) {
              id = defaultSpeciality.id
            }

            const obj = {
              actionactuationid: "",
              desccouncil: infoCouncil.council,
              descriptionactionactuation: "",
              descriptionspeciality: item.name,
              id: id,
              profcouncilid: item.councilId,
              specialityid: item.specialityId,
              state: infoCouncil.state
            }
            arr.push(obj)
            // console.log('speciality', obj);
          }
          if (item.hasOwnProperty('councilId') && item.hasOwnProperty('specialityId') && item.hasOwnProperty('actionActuationId')) {
            let id = ""
            const infoCouncil = getInfoCouncil(item.councilId)
            const infoSpeciality = getInfoSpeciality(infoCouncil.specialityList, item.specialityId)
            const infoActuation = getInfoActuation(infoSpeciality.actuationList, item.actionActuationId)
            const defaultCouncil = checkDefault(item.councilId, "profcouncilid")
            const defaultSpeciality = checkDefault(item.specialityId, "specialityid")
            const defaultActuation = checkDefault(item.actionActuationId, "actionactuationid")

            if (defaultCouncil && !defaultSpeciality && !defaultActuation) {
              const size = specialities.filter(el => el.councilId === item.councilId).length

              if (size === 1) {
                id = defaultCouncil.id
              }
            }

            if (defaultCouncil && defaultSpeciality && !defaultActuation) {
              id = defaultSpeciality.id
            }

            if (defaultCouncil && defaultSpeciality && defaultActuation) {
              id = defaultActuation.id
            }
            // console.log(item.name, 'só passou')

            const obj = {
              actionactuationid: item.actionActuationId,
              desccouncil: infoCouncil.council,
              descriptionactionactuation: infoActuation.description,
              descriptionspeciality: infoSpeciality.description,
              id: id,
              profcouncilid: item.councilId,
              specialityid: item.specialityId,
              state: infoCouncil.state
            }
            arr.push(obj)
            // console.log('actuation', obj);
          }
        })
      } else {
        let id = ""
        const infoCouncil = getInfoCouncil(dataService.value.specialitiesList.councilId)
        const defaultCouncil = checkDefault(dataService.value.specialitiesList.councilId, "profcouncilid")
        const defCouncil = checkDefault(dataService.value.specialitiesList.councilId, "councilid")

        if (defaultCouncil) {
          id = defaultCouncil.id
        }
        if (!infoCouncil) {
          console.log('usar', defaultCouncil)
        }

        const obj = {
          actionactuationid: "",
          desccouncil: infoCouncil && infoCouncil.council,
          descriptionactionactuation: "",
          descriptionspeciality: "",
          id: id,
          profcouncilid: dataService.value.specialitiesList.councilId,
          specialityid: "",
          state: infoCouncil && infoCouncil.state
        }

        arr.push(obj)
        // console.log('só council', obj)
      }

      const newObj = { ...dataService.value, specialitiesList: arr }

      return newObj
    }

    function shallowEqual(object1, object2) {
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);
      if (keys1.length !== keys2.length) {
        // console.log('length diferente')
        return false;
      }
      for (let key of keys1) {
        if (object1[key] !== object2[key]) {
          // console.log(object1[key], ' !== ', object2[key])
          return false;
        }
      }
      return true;
    }

    const validationService = (servicetypesList, defaultTypes) => {
      let boolService = true

      const validationObj = servicetypesList.map(item => {
        let indexTrue = false
        const equalTrue = defaultTypes.filter(element => shallowEqual(item, element))

        if (equalTrue.length > 0) {
          indexTrue = true
        }
        return indexTrue
      })

      validationObj.map(item => {
        if (!item) {
          boolService = false
        }
      })

      if (boolService && (validationObj.length === dataDefault.value.servicetypesList.length)) {
        return true
      }

      return false
    }

    const validationSpecialities = (specialitiesList, defaultSpeciality) => {
      let boolSpecialities = true

      const validationObj = specialitiesList.map(item => {
        let indexTrue = false
        const equalTrue = defaultSpeciality.filter(element => shallowEqual(item, element))

        if (equalTrue.length > 0) {
          indexTrue = true
        }

        return indexTrue
      })

      validationObj.map(item => {
        if (!item) {
          boolSpecialities = false
        }
      })

      if (specialitiesList.length === 0) {
        return true
      }

      if (boolSpecialities && (validationObj.length === dataDefault.value.specialitiesList.length)) {
        return true
      }

      return false
    }

    const boolChanges = () => {
      if (mounted.value) {
        const specialities = returnSpecialities()
        const objOld = {
          description: dataDefault.value.description,
          id: dataDefault.value.id,
          name: dataDefault.value.name,
          pb_return: dataDefault.value.pb_return,
          status: dataDefault.value.status,
          sus: dataDefault.value.sus,
          susprocedure: dataDefault.value.susprocedure,
          timeReturn: dataDefault.value.timeReturn,
          tuss: dataDefault.value.tuss,
          tussterm: dataDefault.value.tussterm,
        }
        const objNew = {
          description: specialities.description,
          id: specialities.id,
          name: specialities.name,
          pb_return: specialities.pb_return,
          status: specialities.status,
          sus: specialities.sus,
          susprocedure: specialities.susprocedure,
          timeReturn: specialities.timeReturn,
          tuss: specialities.tuss,
          tussterm: specialities.tussterm,
        }

        const val1 = shallowEqual(objOld, objNew)
        const val2 = validationSpecialities(specialities.specialitiesList, dataDefault.value.specialitiesList)
        const val3 = validationService(specialities.servicetypesList, dataDefault.value.servicetypesList)


        if (val1 && val2 && val3) {
          return true
        }

        return false
      }
      return false
    }

    const onSubmit = () => {
      if (props.type === "View") {
        ctx.emit("update:type", "Edit")
      } else {
        const newObj = returnSpecialities();

        if (!boolChanges()) {
          UpdateService(newObj)
        }
      }
    };

    const getType = () => {
      if (props.type === "Edit") return "Editar Serviço";
      if (props.type === "View") return "Detalhes do Serviço";

      return "";
    };

    const getBtnSubmit = () => {
      if (props.type === "Edit") return "Salvar";
      if (props.type === "View") return "Editar";

      return "";
    };

    const listActiveRegistries = async () => {
      const req = new ListActiveRegistriesRequest();
      const res = await profileService.listActiveRegistries(req);

      if (res.success) {
        councilprofessionalsList.value = res.councilprofessionalsList;
      }
    };

    const getServiceType = async (serviceTypes, type) => {
      const obj = await serviceTypes.find((item) => item.servicemode === type);
    };

    const listServiceId = async (id) => {
      const req = new ListServiceIdRequest();
      req.setServiceid(id);
      const res = await profileService.listServiceId(req);
      console.log('item', res.servicesList[0])
      console.log('id: ' + id)

      if (res.success) {
        const serviceList = res.servicesList[0]
        const serviceType = serviceList.servicetypesList
        const spealitiesList = serviceList.specialitiesList

        dataDefault.value = JSON.parse(JSON.stringify(serviceList))
        dataService.value = JSON.parse(JSON.stringify(serviceList))
        getServiceType(serviceType, "Presencial");
        getServiceType(serviceType, "Videochamada");
        getServiceType(serviceType, "Homecare");
        isLoading.value = false
      }
    };

    const clearTussSusDescription = () => {
      const ds = dataService.value;
      ds.tuss = ""
      ds.tussterm = ""
      ds.sus = ""
      ds.susprocedure = ""
      ds.description = ""
    }

    const suggestionSusWithTuss = async () => {
      const req = new ListSusCorrelatedToTussRequest();
      req.setTusskey(dataService.value.tuss);

      const res = await tussService.listSusCorrelatedToTuss(req);

      if (res.success) {
        suggestionSus.value = res.correlationsList
      }
    }

    const errorValidationTypes = () => {
      const { servicetypesList } = returnSpecialities();
      let bool = true
      console.log(servicetypesList)
      servicetypesList.map(item => {
        if (item.status === "Ativo") {
          bool = false
        }
      })
      return bool
    }

    watch(() => [dataService.value.tuss, dataService.value.tussterm],
    async ([newT, newTT], _) => {
      if (mounted.value) {
        if (!newT, !newTT) {
          suggestionSus.value = []
          dataService.value.sus = ""
          dataService.value.susprocedure = ""
          dataService.value.description = ""
        } else {
          const req = new ListSusCorrelatedToTussRequest();
          req.setTusskey(newT);

          const res = await tussService.listSusCorrelatedToTuss(req);

          if (res.success) {
            suggestionSus.value = res.correlationsList;
          }
        }
      }
    })

    onMounted(async () => {
      await listActiveRegistries();
      await listServiceId(props.id);
      await suggestionSusWithTuss();
      mounted.value = await true;
    });

    return {
      isLoading,
      councilprofessionalsList,
      close,
      onUpdate,
      onSubmit,
      getType,
      getBtnSubmit,
      dataService,
      suggestionTuss,
      suggestionSus,
      getServiceType,
      clearTussSusDescription,
      isMedical,
      discardChanges,
      boolChanges,
      mounted,
      validation,
      msgErros,
      errorValidationTypes,
    };
  },
};
</script>

<style lang="scss" scoped>
.content {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inative {

  opacity: .6;

  &:hover {
    cursor: not-allowed;
  }
}

.addService-button {
  animation: none !important;
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
}

::v-deep(button) {
  height: 40px;
}

::v-deep(input) {
  max-width: 100%;
  height: 45px;
  border-radius: 8px;
  color: #2d313d !important;
  font-weight: 400 !important;
}

::v-deep(textarea) {
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding: 1rem;
  font-size: 14px;
  width: 100%;
  outline: none;
  color: #2d313d !important;

  &:focus {
    border-color: #ff6a33;
  }

  &:hover {
    outline: none;
    border-color: #ff6a33;
  }

  &:disabled {
    color: #b9bdbf;
    border: 1px solid#e0e0e0;
    opacity: .6;
    &::placeholder {
      color: #b9bdbf;
    }

    &:hover {
      border-color: #e0e0e0;
    }
  }
}

::v-deep(.field-checkbox) {
  margin-bottom: 8px;
}

::v-deep(.p-dropdown) {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

::v-deep(.p-inputtextarea) {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #828282;
  border: 1px solid #F2F2F2;
  border-radius: 8px;
}
</style>
