<template>
  <div class="card-inperson flex flex-column" :class="borderError" :style="type === 'View' && 'opacity: .6'">
    <div class="flex justify-content-between align-items-center">
      <div class="flex align-items-center">
        <div class="tag-color" />
        <span class="text-label ml-10">Presencial</span>
      </div>

      <InputSwitch v-model="active" :disabled="type === 'View'" />
    </div>
    <div v-if="active" class="flex mt-3">
      <div class="flex flex-column w-6 pr-2">
        <label for="duration" class="text-bold span-color fs-2 mb-1"> Duração </label>
        <Duration v-model="duration" :disabled="type === 'View'" />
        <p class="p-invalid" v-if="msgErros.Presencial.duration && !duration">{{ msgErros.Presencial.duration }}</p>
      </div>
      <div class="flex flex-column w-6 pl-2">
        <label for="price" class="text-bold span-color fs-2 mb-1"> Valor </label>
        <InputNumber class="txt-right" v-model="currency" mode="currency" currency="BRL" locale="pt-BR"
          :disabled="type === 'View'" :class="(msgErros.Presencial.currency && !currency) && 'p-invalid'"/>
        <p class="p-invalid" v-if="msgErros.Presencial.currency && !currency">{{ msgErros.Presencial.currency }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Duration from "../../../../components/Duration/Duration";
import { ref, onMounted, watch, computed } from "vue";

export default {
  props: ["servicetypesList", "msgErros", "type"],
  emits: ["update:servicetypesList"],
  components: { Duration },
  setup(props, { emit }) {
    const mounted = ref(false)
    const id = ref(null)
    const active = ref(false)
    const status = ref('')
    const duration = ref(0);
    const currency = ref(0);

    const borderError = computed(() => {
      if((props.msgErros.Presencial.duration && !duration.value) || (props.msgErros.Presencial.currency && !currency.value)) {
        return 'border-error'
      }
      return ''
    })

    const getPerson = (serviceTypes) => {
      const obj = serviceTypes.find((item) => item.servicemode === 'Presencial');
      return obj
    };

    const getObj = () => {
      return {
        id: id.value,
        status: active.value ? 'Ativo' : 'Inativo',
        estimatedTime: duration.value,
        value: currency.value,
        servicemode: "Presencial",
        maxDisplacement: 0,
        valuekm: 0,
        valuepercentage: 0
      }
    }

    const getIndex = () => {
      const index = props.servicetypesList.findIndex((item) => item.servicemode === 'Presencial')

      return index
    }

    watch(() => active.value,
      (newValue) => {
        if (mounted.value) {
          const arr = [...props.servicetypesList]
          const obj = { ...getObj() }
          const index = getIndex();

          if (index > -1) {
            arr[index] = obj
          } else {
            arr.push(obj)
          }

          emit("update:servicetypesList", arr)
        }
      })

    watch(() => duration.value, async (newValue) => {
      if (mounted.value) {
        const arr = props.servicetypesList
        const obj = getObj();
        const index = getIndex();

        if (index !== -1) {
          arr[index] = { ...obj }
        } else {
          arr.push(obj)
        }
        emit("update:servicetypesList", arr)
      }
    })

    watch(() => currency.value, async (newValue) => {
      if (mounted.value) {
        const arr = props.servicetypesList
        const obj = getObj();
        const index = getIndex();

        if (!newValue) {
          obj.value = 0
          currency.value = 0
        }

        if (index !== -1) {
          arr[index] = { ...obj }
        } else {
          arr.push(obj)
        }
        emit("update:servicetypesList", arr)
      }
    })

    onMounted(async () => {
      const person = await getPerson(props.servicetypesList);
      console.log(person)
      if (person) {
        id.value = person.id
        active.value = person.status === 'Ativo' ? true : false
        status.value = person.status
        duration.value = person.estimatedTime;
        currency.value = person.value
      }
      mounted.value = await true
    });

    return { active, duration, currency, borderError, getObj };
  },
};
</script>

<style lang="scss" scoped>
.tag-color {
  width: 16px;
  height: 16px;
  background: #a2c0d4;
  border-radius: 4px;
}
.border-error{
  border: 1px solid red !important;
}

.text-label {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: #4f4f4f;
}
.card-inperson{
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #F2F2F2;
}

.mtop-8 {
  margin-top: 8px !important;
}

.mleft-8 {
  margin-left: 8px !important;
}

::v-deep(.txt-right) {
  input {
    text-align: right !important;
  }
}
</style>
