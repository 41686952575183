<template>
  <div class="limit p-3">
    <h1 class="title-header">Serviços oferecidos</h1>
    <div class="container-service" style="margin-bottom: 28px">
      <div class="left">
        <Button class="btn principal" @click="add"> Adicionar Serviço </Button>
        <div class="btns-frequency">
          <Button
            class="btn"
            v-for:="(item, index) in btnFilter"
            :key="index"
            :class="isFilter(item.label)"
            @click="changeFilter(item)"
          >
            {{ item.label }}
          </Button>
        </div>
      </div>

      <!-- Search component -->
      <span class="container-search p-input-icon-left">
        <i class="pi pi-search" v-tooltip.left="'Buscar'" @change="onChangeSearch(searchInput)"
          v-if="!isLoading" />
        <i class="pi pi-spin pi-spinner" v-else />
        <InputText class="search__input text-center" type="text" placeholder="Pesquisar" v-model="searchInput"
          @change="onChangeSearch(searchInput)" :disabled="isLoading" />
      </span>
    </div>
    
    <LegendsMode />
    <Loader :qto="myServicesList" v-if="isLoading" />
    <List
      :myServicesList="myServicesList"
      :onChangeSwitch="onChangeSwitch"
      v-model:dialogViewEditService="viewEditService"
      :filter="filter"
      :congratulationsMessage="congratulationsMessage"
      v-else
    />
    <div class="surface-card p-5" v-if="myServicesList.length === 0 && !isLoading">
      <div class="service-content">
        <i class="fas fa-medkit icon"></i>
        <p>Nenhum serviço cadastrado.</p>
      </div>
    </div>
  </div>

  
  <DialogAddService v-model:visible="dialogAddService" @serviceAddSucess="refreshList" @congratulations="funCongratulations" />
  <!-- <DialogOldAddService v-model:visible="dialogAddService" @serviceAddSucess="refreshList" v-if="dialogAddService" /> -->
  <DialogViewEditService 
    v-model:visible="viewEditService.status" 
    v-model:type="viewEditService.type" 
    v-model:id="viewEditService.id"
    @refreshList="refreshList"
    v-if="viewEditService.status"
  />
</template>

<script>
import { onMounted, ref } from "vue";
import DialogOldAddService from "./DialogOldAddService";
import DialogAddService from "./DialogAddService/DialogAddService.vue";
import DialogViewEditService from "./DialogViewEditService";
import LegendsMode from "./LegendsMode";
import Loader from "./Loader";
import List from "./List";
import profileService from "../../core/services/professionalProfileService";
import {
  ListServiceRequest,
  SearchListServiceRequest,
  UpdateStatusServiceRequest,
} from "../../core/grpc/generated/professionalProfile_pb";

export default {
  components: {
    DialogAddService,
    DialogOldAddService,
    DialogViewEditService,
    Loader,
    List,
    LegendsMode,
  },
  setup() {
    const congratulationsMessage = ref(false)
    var isLoading = ref(true);
    const searchInput = ref("");
    const dialogAddService = ref(false);
    const viewEditService = ref({
      status: false,
      type: null,
      id: null,
    });
    const filter = ref("Todos");
    const typeConsult = ref("Filter");
    const btnFilter = [
      { label: "Todos" },
      { label: "Ativos" },
      { label: "Inativos" },
    ];
    // The states were defined for the realization of the first loader.
    const myServicesList = ref([
      { status: "Ativo" },
      { status: "Ativo" },
      { status: "Inativo" },
    ]);

    const add = () => dialogAddService.value = true;

    const isFilter = (item) => {
      if (item !== filter.value) {
        return "inative";
      }
    };

    const changeFilter = (item) => {
      const input = searchInput.value;
      filter.value = item.label;

      if (input) {
        if (input.length >= 3) {
          onChangeSearch(input);
        } else {
          listServices(item.label);
        }
      } else {
        listServices(item.label);
      }
    };

    const listServices = async (status) => {
      isLoading.value = true;
      const request = new ListServiceRequest();
      request.setStatus(status);
      const res = await profileService.listServices(request);


      if (res.success) {
        console.log(res.servicesList);
        myServicesList.value = res.servicesList;
      }
      isLoading.value = false;
    };

    const onChangeSwitch = async (previousValue, serviceId) => {
      // console.log("onChangeSwitch");

      let obj = null;
      if (previousValue === "Ativo") {
        obj = {
          serviceId: serviceId,
          status: "Inativo",
        }
      } else if (previousValue === "Inativo") {
        obj = {
          serviceId: serviceId,
          status: "Ativo",
        }
      }
      if (obj) {
        const request = new UpdateStatusServiceRequest()
        request.setServiceid(obj.serviceId)
        request.setStatus(obj.status)
        const res = await profileService.updateStatus(request)
        if (res.success) {
          changeFilter({ label: filter.value })
        }
      }
    };

    const onChangeSearch = async (value) => {
      // console.log("onChangeSearch");
      if (value.length >= 3) {
        isLoading.value = true;
        typeConsult.value = "Search";
        const request = new SearchListServiceRequest();
        request.setStatus(filter.value)
        request.setWord(value);

        const resp = await profileService.searchListService(request);
        if (resp.success == true) {
          // console.log(resp.servicesList);
          myServicesList.value = resp.servicesList;
        }
        isLoading.value = false;
      } else {
        if (typeConsult.value === "Search") {
          isLoading.value = true;

          await listServices(filter.value)
          typeConsult.value = "Filter";
          isLoading.value = false;
          // console.log(isLoading.value, typeConsult.value);
        }
      }
    };

    const refreshList = () => {
      filter.value = "Todos";
      searchInput.value = "";
      listServices("Todos");
    }

    const funCongratulations = bool => {
      if(bool !== congratulationsMessage.value){
        congratulationsMessage.value = bool;
      }
    }

    onMounted(async () => {
      await listServices("Todos");
    });

    return {
      congratulationsMessage,
      isLoading,
      searchInput,
      dialogAddService,
      viewEditService,
      myServicesList,
      filter,
      btnFilter,
      add,
      isFilter,
      changeFilter,
      listServices,
      onChangeSwitch,
      onChangeSearch,
      refreshList,
      funCongratulations,
    };
  },
};
</script>

<style scoped lang="scss">
.container-service {
width: 100%;
display: flex;
justify-content: space-between;
flex-wrap: wrap;
gap: 8px;
margin-top: 36px !important;

.principal {
  display: flex;
  justify-content: center;
  width: 176px;
  background: linear-gradient(171.55deg, #FF6A33 60.76%, #FF4500 99.68%);
}

input {
  width: 200px;
}

.search__input {
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
}

.left {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 278px);
  gap: 10px;

  .filters {
    display: flex;
    gap: 8px;
  }

  @media (max-width: 970px) {
    width: 100%;
    margin-bottom: 10px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    margin-bottom: 10px;
  }
}
}

.btn {
width: max-content;
height: 40px;
}

.loader {
  margin-top: 30px;
  gap: 8px;
  margin-bottom: 22px;
}

.items{
  margin-top: 30px;
  gap: 8px;
  margin-bottom: 22px;
  min-height: 60px;
  .item {
    min-height: 60px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;
    width: 100%;
    padding: 10px;
    background: white;
    box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
    border-radius: 8px;
  }
}
.txt-btn{
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 31px;
color: #828282;
}

.btns-frequency {
display: flex;
justify-content: center;
flex-wrap: nowrap;

.inative {
  background: #fff !important;
  border: 1px solid #fff;
  color: #828282 !important;
}

.btn:first-child {
  background: #FF6A33;
  padding: 0px 32px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.btn:not(:first-child):not(:last-child) {
  background: #FF6A33;
  padding: 0px 32px;
  border-radius: 0px;
}

.btn:last-child {
  background: #FF6A33;
  padding: 0px 32px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

@media (max-width: 995px) {
  justify-content: flex-start;
}

@media (max-width: 456px) {
  justify-content: flex-start;
  margin-bottom: 10px;
}
}

.search__input {
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);
}

::v-deep(input) {
  height: 40px;
  border-radius: 8px;
  padding: 0 19px;
  color: #bdbdbd;
  border: 1px solid white;
  outline: none;
  &::-webkit-input-placeholder {
    color: #bdbdbd;
  }
  &:focus {
    border-color: #ff6a33;
  }
}

.container-search {
  width: 260px;

  input {
    width: 100% !important;
  }
}
.pi-search {
  color: #BDBDBD !important;
}

.p-input-icon-right {
  cursor: pointer;
  vertical-align: middle;
}
</style>
