<template>
    <div class="flex flex-column">
        <div class="dialog-addservice-profission" :class="borderSelect">
            <h1>Escolha sua profissão e especialidades:</h1>
            <div class="profissions" v-for="council in list" :key="council.councilid">
                <div class="profission" :class="disabled && 'cursor-not-allowed'">
                    <RadioButton 
                        v-model="councilValue" 
                        :inputId="council.councilid" 
                        :name="council.council + ' - ' + council.state" 
                        :value="council.councilid" 
                        :disabled="disabled"
                        @change="typeChangeProfission = 'council'"
                    />    
                    <b>{{council.council}} - {{council.state}}</b>   
                </div>
                <div class="specialities" 
                    v-for="(speciality, index) in council.specialityList" 
                    v-if="councilValue === council.councilid"
                >
                    <div class="specialitie">
                        <Checkbox 
                            v-model="specialities" 
                            :inputId="speciality.specialtyid" 
                            :name="speciality.description" 
                            :value="speciality.specialtyid"
                            @change="typeChangeProfission = 'speciality'"
                        />
                        <b>{{ speciality.description }}</b>
                    </div>
                    <div class="actuations" 
                        v-for="actuation in speciality.actuationList" 
                        v-if="checkSpecialityChecked(speciality.specialtyid)"
                    >
                        <div class="actuation">
                            <Checkbox 
                                :inputId="actuation.actuationid" 
                                :name="actuation.description"
                                :value="{
                                    specialtyid: speciality.specialtyid,
                                    actuationid: actuation.actionactuationid
                                }"
                                v-model="actuations"
                                @change="typeChangeProfission = 'actuation'"
                            />
                            <b>{{actuation.description}}</b>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="p-invalid" v-if="error && specialitiesList.length === 0">{{error}}</p>
    </div>
</template>

<script>
import { onMounted, ref, watch, computed } from "vue";
import { listProfissions, specialtiesStructure, suggestionTuss } from "./actions.js";

export default {
    props: ["specialitiesList", "listSuggestionsSus", "round", "error"],
    emits: ["update:specialitiesList", "update:listsuggestionstuss", "update:isMedical", "update:listSuggestionsSus", "clearall", "mounted"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        const disabled = computed(() => {
            if(props.round === 'complete'){
                return false;
            }
            if(props.round !== 'profission'){
                return true;
            }            
            return false;
        })
        const borderSelect = computed(() => {
            if(props.error && props.specialitiesList.length === 0){
                return 'border-error';
            }
            if(props.round === 'profission'){
                return 'border-select';
            }
            return '';
        })
        const isMedical = computed(() => {
            if(councilValue.value){
                const res = list.value.find(item => item.councilid === councilValue.value);
                if(res.council === "Médico(a)"){
                    return true;
                }                
            }
            return false;
        });
        const typeChangeProfission = ref("");
        const list = ref([]);
        const councilValue = ref(null);
        const specialities = ref([]);
        const actuations = ref([]);

        const checkSpecialityChecked = (specialtyid) => {
            return specialities.value.includes(specialtyid);
        }

        const getObj = () => {
            return {
                councilid: councilValue.value,
                specialityList: specialities.value,
                actuationList: actuations.value
            }
        }

        const getNameSpeciality = (arr) => {
            let councilList = list.value.find(item => item.councilid === councilValue.value);
            const names = [];

            arr.map(profission => {
                if(profission.specialtyid){
                    const res = councilList.specialityList.find(item => item.specialtyid === profission.specialtyid);
                    names.push(res.description);
                }
            });
            return names;
        }

        const GetProfissions = async () => {
            const arr = await specialtiesStructure(getObj());
            const names = await getNameSpeciality(arr);

            if(isMedical && typeChangeProfission.value === 'speciality'){
                const listSuggestionsTuss = await suggestionTuss(names);
                emit("update:listsuggestionstuss", listSuggestionsTuss);
            }

            console.log(arr)
            
            emit("update:specialitiesList", arr);
            emit("update:isMedical", isMedical);
        }

        watch(() => councilValue.value, async (newValue, oldValue) => {
            if(specialities.value.length > 0){
                specialities.value = await []; // clear specialities to change council (RADIO BUTTON).
            }
            if(actuations.value.length > 0){
                actuations.value = await []; // clear actuations to change council (RADIO BUTTON).
            }

            if(typeChangeProfission.value === 'council'){
                await GetProfissions()
                emit("update:listsuggestionstuss", []);
                emit("update:listSuggestionsSus", []);
                emit("clearall");
            }
        });
        
        watch(() => specialities.value, async (newValue, oldValue) => {
            // let add = newValue.filter((a) => !oldValue.includes(a)) // se adicionou
            let remove = oldValue.filter((a) => !newValue.includes(a)) // se removeu

            if(remove){
                const arrRemoveChild = await actuations.value.filter((a) => a.specialtyid !== remove[0]);
                actuations.value = await arrRemoveChild;
            }
            if(typeChangeProfission.value === 'speciality'){
                await GetProfissions()
            }
        });
        
        watch(() => actuations.value, async (newValue, oldValue) => {  
            if(typeChangeProfission.value === 'actuation'){
                await GetProfissions()
            }
        });

        onMounted(async () => {
            const resListProfessions = await listProfissions();

            list.value = await resListProfessions;
            emit('mounted')
        })

        return {
            list,
            councilValue,
            specialities,
            actuations,
            checkSpecialityChecked,
            getObj,
            specialtiesStructure,
            typeChangeProfission,
            isMedical,
            borderSelect,
            disabled,
            // bool,
        }
    },
};
</script>


<style lang="scss" scoped>
.dialog-addservice-profission {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #FAFAFA;
    padding: 17px;
    // border: 1px solid #FF6A33;
    border-radius: 8px;

    h1{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #4F4F4F;
        margin-bottom: 20px !important;
    }
    b{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #4F4F4F;
    }
    .profissions{
        display: flex;
        flex-direction: column;
        margin-bottom: 8px;
        
        .profission{
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 10px;
        }
        .specialities:last-child{
            padding-bottom: 0;
            margin-bottom: 4px;
        }
    }
    .profissions:last-child{
        margin-bottom: 0px !important;
        .profission:last-child{
            margin-bottom: 0px !important;
        }
    }
    .specialities{
        display: flex;
        flex-direction: column;
        margin-left: 7px;
        border-left: 2px solid #FF6A33;
        padding-bottom: 2px;
        .specialitie{
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 4px 0; 

            margin-left: 8px;
            b{color: #828282;}
            ::v-deep(.p-checkbox-box) {
                border: 2px solid #BDBDBD !important;
            }
            ::v-deep(.p-checkbox-checked) {
                .p-checkbox-box{
                    border: 2px solid #FF6A33 !important;
                }
            }
        }
    }
}
.actuations{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    border-left: 2px solid #A2C0D4;
    .actuation{
        display: flex;
        align-items: center;
        padding: 5px 0;
        gap: 8px;

        margin-left: 8px;
        b{color: #828282;}
        ::v-deep(.p-checkbox-box) {
            border: 2px solid #A2C0D4 !important;
        }
        ::v-deep(.p-checkbox-checked) {
            .p-checkbox-box{
                background: #A2C0D4 !important;
            }
        }
    }
}

.border-error{
    border: 1px solid red;
}

.border-select{
    border: 1px solid #FF6A33;
}

::v-deep(.p-checkbox-icon) {
    transform: scale(.8);
    margin-left: 1px;
    margin-top: 1px;
}

::v-deep(.p-radiobutton-box) {
    border: 2px solid #BDBDBD;
}

::v-deep(.p-radiobutton-icon) {
    background: #FAFAFA !important;
}

::v-deep(.p-radiobutton-checked) {
    .p-radiobutton, .p-radiobutton-box, .p-highlight{
        background: #FF6A33 !important;
    }
}
</style>