<template>
  <div class="card-homecare flex flex-column" :style="type === 'View' && 'opacity: .6'">
    <div class="flex justify-content-between align-items-center">
      <div class="flex align-items-center">
        <div class="tag-color" style="background: #ff6a33" />
        <span class="text-label ml-10">Homecare</span>
      </div>
      <InputSwitch v-model="active" :disabled="type === 'View'" />
    </div>

    <div v-if="active" class="flex flex-column mt-3">
      <div class="flex">
        <div class="flex flex-column w-6 pr-2">
          <label for="duration" class="text-bold span-color fs-2 mb-1"> Duração </label>
          <Duration v-model="duration" :disabled="type === 'View'" />
        </div>
        <div class="flex flex-column w-6 pl-2">
          <label for="price" class="text-bold span-color fs-2 mb-1"> Valor </label>
          <InputNumber class="txt-right" v-model="currency" mode="currency" currency="BRL" locale="pt-BR"
            :disabled="type === 'View'" />
        </div>
      </div>
      <div>
        <div class="my-2">
          <span class="fs-2">Deslocamento</span>
        </div>
        <div class="flex">
          <div class="flex flex-column w-6 pr-2">
            <label for="price" class="text-bold span-color fs-2 mb-1">
              Valor por Km
            </label>

            <InputNumber id="expiry" mode="currency" currency="BRL" locale="pt-BR" class="txt-right" v-model="km"
              suffix=" / Km" :disabled="type === 'View'" />
          </div>
          <div class="flex flex-column w-6 pl-2">
            <label for="price" class="text-bold span-color fs-2 mb-1">
              Raio de abrangência
            </label>
            <InputNumber id="expiry" class="txt-right" v-model="raio" suffix=" Km" :disabled="type === 'View'" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Duration from "../../../../components/Duration/Duration";
import { ref, onMounted, watch } from "vue";

export default {
  props: ["servicetypesList", "msgErros", "type"],
  emits: ["update:servicetypesList"],
  components: { Duration },
  setup(props, { emit }) {
    const mounted = ref(false)
    const id = ref(null)
    const active = ref(false)
    const duration = ref(0)
    const currency = ref(0)
    const km = ref(0)
    const raio = ref(0)

    const getHomeCare = (serviceTypes) => {
      const obj = serviceTypes.find((item) => item.servicemode === 'Homecare');
      return obj
    };

    const getObj = () => {
      return {
        id: id.value,
        status: active.value ? "Ativo" : "Inativo",
        estimatedTime: duration.value,
        value: currency.value,
        valuekm: km.value,
        maxDisplacement: raio.value,
        servicemode: "Homecare",
        valuepercentage: 0
      }
    }

    const getIndex = () => {
      const index = props.servicetypesList.findIndex((item) => item.servicemode === 'Homecare')

      return index
    }

    watch(() => active.value,
    async (newValue) => {
      if (mounted.value) {
        const arr = props.servicetypesList
        const obj = getObj();
        const index = getIndex();

        if (index > -1) {
          arr[index] = obj
        } else {
          arr.push(obj)
        }
        emit("update:servicetypesList", arr)
      }
    })

    watch(() => duration.value,
    async (newValue) => {
      if (mounted.value) {
        const arr = props.servicetypesList
        const obj = getObj();
        const index = getIndex();

        if (index !== -1) {
          arr[index] = {...obj}
        } else {
          arr.push(obj)
        }
        emit("update:servicetypesList", arr)
      }
    })

    watch(() => [
      currency.value,
      km.value,
      raio.value
    ], async (newValue) => {
      if (mounted.value) {
        const arr = props.servicetypesList
        const obj = await getObj();
        const index = await getIndex();

        if (index > -1) {
          arr[index] = obj
        } else {
          arr.push(obj)
        }
        emit("update:servicetypesList", arr)
      }
    })

    onMounted(async () => {
      const home_care = await getHomeCare(props.servicetypesList);

      if (home_care) {
        id.value = home_care.id
        active.value = home_care.status === 'Ativo' ? true : false
        duration.value = home_care.estimatedTime
        currency.value = home_care.value
        km.value = home_care.valuekm
        raio.value = home_care.maxDisplacement
      }
      mounted.value = await true
    });

    return {
      active,
      duration,
      currency,
      km,
      raio
    };
  },
};
</script>

<style lang="scss" scoped>
.card-homecare{
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #F2F2F2;
  margin-bottom: 8px;
}
.tag-color {
  width: 16px;
  height: 16px;
  background: #a2c0d4;
  border-radius: 4px;
}

.text-label {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: #4f4f4f;
}

.mtop-8 {
  margin-top: 8px !important;
}

::v-deep(.txt-right) {
  input {
    text-align: right !important;
  }
}
</style>
