<template>
    <div class="dialog-addservice-tuss">
        <div :class="disabled && 'cursor-not-allowed'">
            <AutoComplete 
                v-model="textValue" 
                :suggestions="listSuggestions" 
                field="name" 
                placeholder="Digite o código TUSS"
                @complete="completeItem($event)" 
                @item-select="selectedTuss($event)"
                @clear="clearInput"
                :disabled="disabled"
                :class="(error && (!tuss && !tussterm)) && 'p-invalid'"
                forceSelection
            />
            <img
                class="clear-input" 
                src="../../../../assets/icons/myservices/clear-input.png" 
                alt="" 
                v-tooltip.left="'Limpar TUSS, SUS e Descrição.'"
                @click="clearInput"
                v-if="viewClear"
            />
        </div>
        <ul v-if="listSuggestionsTuss.length > 0 && !textValue">
            <li v-for="item in listSuggestionsTuss" :key="item.tuss" @click="checkedTuss(item)">
                {{item.tuss}} - {{item.tussterm}}
            </li>
        </ul>
    </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";
import { searchTuss, suggestionSus } from "./actions.js";

export default {
    props: ["tuss", "tussterm", "listSuggestionsTuss", "listSuggestionsSus", "disabled", "error"],
    emits: ["update:tuss", "update:tussterm", "update:listSuggestionsSus", "suggestionDescription", "clearSusDescription"],
    setup(props, { emit }) {
        const viewClear = computed(() => {
            if(typeof(textValue.value) === 'object'){
                if(textValue.value){
                    return true;
                }
            }
            return false;
        })
        const textValue = ref("");
        const listSuggestions = ref([]);

        const completeItem = (key) => {
            setTimeout(async () => {
                if(key.query.length >= 3){
                    const arrSearch = await searchTuss(key.query);
                    listSuggestions.value = arrSearch;
                }else{
                    listSuggestions.value = [];
                }
            }, 250);
        };

        const checkedTuss = async (item) => {
            const obj = {
                ...item,
                name: item.tuss + " - " + item.tussterm,
            }
            textValue.value = obj;
            emit("update:tuss", item.tuss);
            emit("update:tussterm", item.tussterm);
            emit("update:listSuggestionsSus", await suggestionSus(item.tuss));
        }

        const clearInput = () =>{
            textValue.value = ""
            emit("update:tuss", "");
            emit("update:tussterm", "");
            emit("clearSusDescription")
        }

        const selectedTuss = async ({value}) => {
            const { code, tussterminology } = value;
            emit("update:tuss", code);
            emit("update:tussterm", tussterminology);
            emit("update:listSuggestionsSus", await suggestionSus(code));
        }

        watch( () => props.tuss, (newValue, oldValue) => {
            if(!newValue){
                if(textValue.value){
                    textValue.value = "";
                }
            }
        });

        return {
            textValue,
            listSuggestions,
            completeItem,
            clearInput,
            viewClear,
            checkedTuss,
            selectedTuss,
        }
    },
};
</script>


<style lang="scss" scoped>
.dialog-addservice-tuss {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    ul{
        display: flex;
        flex-direction: column;
        list-style: none;
        padding: 0;
        max-height: 200px;
        gap: 6px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 8px;
            cursor: pointer;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #e0e0e0;
            border-radius: 25px;
        }

        &::-webkit-scrollbar-track {
            margin: 10px;
        }
        
        li{
            display: flex;
            align-items: center;
            height: max-content !important;
            padding: 8px 16px;
            background: #FAFAFA;
            border: 1px solid #E9EAEC;
            border-radius: 8px;
            width: calc(100% - 10px);

            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #57595F;

            &:hover{
                border-color: #FF4500;
                cursor: pointer;
            }
        }
    }
    .clear-input{
        background: white;
        position: absolute;
        right: 8px;
        top: 13px;
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
}


.border-select{
    border: 1px solid #FF6A33;
}


::v-deep(.p-autocomplete) {
    width: 100%;
}

::v-deep(.p-autocomplete-input) {
    border-top: 1px solid #F2F2F2;
    border-radius: 8px;
    vertical-align: middle;
    height: 45px;
    padding-right: 32px;
}
</style>