<template>
    <div class="dialog-addservice-description">
        <Textarea 
            :value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            rows="5" 
            cols="30" 
            placeholder="Descrição" 
            :autoResize="true"
            :disabled="disabled"
            :class="(error && !modelValue) && 'p-invalid'"
        />
        <p class="p-invalid" v-if="error && !modelValue">{{error}}</p>
    </div>
</template>

<script>
import { onMounted, ref } from "vue";
// import A from "./A";

export default {
    props: ["modelValue", "disabled", "error"],
    emits: ["update:modelValue"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        const textValue = ref("");

        return {
            textValue,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }

::v-deep(.p-inputtextarea) {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #828282;
    // border: 1px solid #F2F2F2;
    border-radius: 8px;
}
</style>