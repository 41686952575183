<template>
    <Dialog class="modal" :style="{
        width: '402px',
        borderRadius: '8px',
    }" :visible="visible" @hide="close" @update:visible="onUpdate">
        <template #header>
            <h5 class="m-auto">Adicionar Serviço</h5>
        </template>

        <Loader v-if="isLoading" />
        <div class="content" :class="isLoading && 'hidden'">
            <!-- {{msgErros}} -->
            <!-- <ul>
                <li>round: {{round}}</li>
                <li>name: {{dataService.name}}</li>
                <li>description: {{dataService.description}}</li>
                <li>tuss: {{dataService.tuss}}</li>
                <li>tussterm: {{dataService.tussterm}}</li>
                <li>sus: {{dataService.sus}}</li>
                <li>susprocedure: {{dataService.susprocedure}}</li>
            </ul> -->
           
            <NameService v-model="dataService.name" :round="round" :error="msgErros.name" />
            <Profission 
                v-model:specialitiesList="dataService.specialitiesList" 
                :round="round" 
                v-model:listSuggestionsSus="listSuggestionsSus"
                @update:listsuggestionstuss="callSuggestionTuss" 
                @update:isMedical="changeMedical"
                @clearall="clearall"
                @mounted="isLoading = false"
                :class="round === 'name' && 'inative'"
                :error="msgErros.serviceHasSpecialities"
            />
            <Tuss 
                v-model:tuss="dataService.tuss" 
                v-model:tussterm="dataService.tussterm" 
                v-model:listSuggestionsSus="listSuggestionsSus" 
                :listSuggestionsTuss="listSuggestionsTuss" 
                :disabled="disabled"
                @suggestionDescription="sugDescription"
                @clearSusDescription="clearSusDescription"
                :error="msgErros?.tuss"
                v-if="visibleTussSus"
            />
            <Sus 
                v-model:sus="dataService.sus"
                v-model:susprocedure="dataService.susprocedure"
                v-model:listSuggestionsSus="listSuggestionsSus" 
                v-model:description="dataService.description"
                :disabled="disabled"
                @suggestionDescription="sugDescription"
                :error="msgErros?.tuss"
                v-if="visibleTussSus"
            />           
            <p class="p-invalid" v-if="msgErros?.tuss && (!(dataService.sus && dataService.susprocedure) || !(dataService.tuss && dataService.tussterm)) && isMedical">
                {{msgErros?.tuss}}
            </p>

            <Description v-model="dataService.description" :disabled="disabled" :class="disabled && 'inative'" :error="msgErros.description" />
            <!-- <ul>
                <b>Direito a retorno</b>
                <li>pb_return: {{dataService.pb_return}}</li>
                <li>timeReturn: {{dataService.timeReturn}}</li>
            </ul> -->
            <ReturnMedical 
                v-model:pb_return="dataService.pb_return" 
                v-model:timeReturn="dataService.timeReturn" 
                :disabled="disabled" 
                :class="disabled && 'inative'" 
            />
            <!-- <ul>
                <b>Presencial</b>
                <li>status: {{inPerson.active}}</li>
                <li>valor: {{inPerson.value}}</li>
                <li>duração: {{inPerson.duration}}</li>
            </ul> -->
            <InPerson 
                v-model:inPerson="inPerson"
                :disabled="disabled" 
                :class="disabled && 'inative'" 
                :error="msgErros.Presencial"
                :errorAll="errorAllServiceTypes"
            />
            <!-- <ul>
                <b>VideoChamada</b>
                <li>status: {{videoCall.active}}</li>
                <li>valor: {{videoCall.value}}</li>
                <li>duração: {{videoCall.duration}}</li>
            </ul> -->
            <VideoCall 
                v-model:videoCall="videoCall" 
                :disabled="disabled" 
                :class="disabled && 'inative'" 
                :error="msgErros.Videochamada"
                :errorAll="errorAllServiceTypes"
            />
            <!-- <ul>
                <b>HomeCare</b>
                <li>status: {{homeCare.active}}</li>
                <li>valor: {{homeCare.value}}</li>
                <li>duração: {{homeCare.duration}}</li>
                <li>km: {{homeCare.km}}</li>
                <li>raio: {{homeCare.raio}}</li>
            </ul> -->
            <HomeCare 
                v-model:homeCare="homeCare" 
                :disabled="disabled" 
                :class="disabled && 'inative'" 
                :error="msgErros.Homecare"
                :errorAll="errorAllServiceTypes"
            />      
            <p class="p-invalid" v-if="msgErros?.professionalServiceTypes && (!inPerson.active && !videoCall.active && !homeCare.active)">
                {{ msgErros?.professionalServiceTypes }}
            </p>  
        </div>

        <template #footer>
            <div class="flex justify-content-end align-items-center h-full">
                <Button label="Salvar" class="addService-button" @click="onSubmit" />
            </div>
        </template>
    </Dialog>
</template>
  
<script>
import { ref, onMounted, watch, reactive, computed } from "vue";
import NameService from "./NameService";
import Profission from "./Profission";
import Tuss from "./Tuss";
import Sus from "./Sus";
import Description from "./Description";
import ReturnMedical from "./ReturnMedical";
import InPerson from "./InPerson";
import VideoCall from "./VideoCall";
import HomeCare from "./HomeCare";
import Loader from "../DialogViewEditService/Loader";
import { addService, suggestionDescription, DisplayMessage } from "./actions.js"
// import Loader from "./Loader";

export default {
    props: ["visible",],
    emits: ["serviceAddSucess", "update:visible", "congratulations"],
    components: {
        NameService,
        Profission,
        Tuss,
        Sus,
        Description,
        ReturnMedical,
        InPerson,
        VideoCall,
        HomeCare,
        Loader,
    },
    setup(props, ctx) {
        const isLoading = ref(true);
        const isMedical = ref(false);
        const mounted = ref(false);
        const clearTuss = ref(false);
        const clearSus = ref(false);
        const listSuggestionsTuss = ref([])
        const listSuggestionsSus = ref([])
        const disabled = computed(() => {return round.value !== 'complete';})
        const dataService = ref({
            name: "",
            description: "",
            tuss: "",
            tussterm: "",
            sus: "",
            susprocedure: "",
            pb_return: false,
            timeReturn: 0,
            specialitiesList: [],
        });        

        const msgErros = ref({
            name: null,
            professionalServiceTypes: null,
            description: null,
            Presencial: { duration: null, currency: null },
            Videochamada: { duration: null, currency: null },
            Homecare: { duration: null, currency: null, km: null },
        })
        
        const visibleTussSus = computed(() => {
            const data = dataService.value;
            if(data.name && data.specialitiesList.length > 0 && isMedical.value) {
                return true;
            }
            if(!data.name && data.specialitiesList.length === 0){ return true; }
            if(data.name && data.specialitiesList.length === 0){ return true; }
            if(data.name && isMedical.value){ return true; }

            return false;
        })
        const round = computed(() => {
            const data = dataService.value;
            if(!data.name){ return 'name' }
            if(data.name && data.specialitiesList.length === 0){
                return 'profission'
            }

            return 'complete';
        })
        
        const errorAllServiceTypes = computed(() => {
            if(msgErros.value?.professionalServiceTypes && (!inPerson.value.active && !videoCall.value.active && !homeCare.value.active) ){
                return 'border-error'
            }

            return false;
        })

        const inPerson = ref({
            active: false,
            duration: 0,
            value: 0,
        });
        const videoCall = ref({
            active: false,
            duration: 0,
            value: 0,
        });
        const homeCare = ref({
            active: false,
            duration: 0,
            value: 0,
            km: 0,
            raio: 0,
        });

        const close = () => {
            ctx.emit("update:visible", false);
            clear();
        }

        const onUpdate = (val) => {
            (!val) && ctx.emit("update:visible", val);
        }

        const onSubmit = async () => {
            const obj = {
                data: dataService.value,
                inPerson: inPerson.value,
                videoCall: videoCall.value,
                homeCare: homeCare.value,
            }
            const res = await addService(obj);

            console.log(res);

            if(res.success){
                const rMessage = await DisplayMessage();
                ctx.emit("congratulations", rMessage);
                close();
                ctx.emit("serviceAddSucess");
            }else{
                msgErros.value = res.data;
            }
        }

        const callSuggestionTuss = (list) => listSuggestionsTuss.value = list;

        const changeMedical = (event) => isMedical.value = event.value;

        const sugDescription = async () => {
            const {tuss, sus} = dataService.value
            const description = await suggestionDescription({tuss: tuss, sus: sus});
            dataService.value.description = description;
        }

        const clearSusDescription = () => {
            dataService.value.sus = "";
            dataService.value.susprocedure = "";
            dataService.value.description = "";
            listSuggestionsSus.value = [];
        }

        const clear = () => {
            dataService.value = {
                name: "",
                description: "",
                status: false,
                tuss: "",
                tussterm: "",
                sus: "",
                susprocedure: "",
                pb_return: false,
                timeReturn: 0,
                specialitiesList: [],
                servicetypesList: [],
            }
            listSuggestionsTuss.value = [];
            listSuggestionsSus.value = [];
            inPerson.value = {
                active: false,
                duration: 0,
                value: 0,
            }
            videoCall.value = {
                active: false,
                duration: 0,
                value: 0,
            }
            homeCare.value = {
                active: false,
                duration: 0,
                value: 0,
                km: 0,
                raio: 0,
            }
            msgErros.value = {
                name: null,
                professionalServiceTypes: null,
                description: null,
                Presencial: { duration: null, currency: null },
                Videochamada: { duration: null, currency: null },
                Homecare: { duration: null, currency: null, km: null },
            }
            isLoading.value = true;
        }
        
        const clearall = () => {
            dataService.value.tuss = "";
            dataService.value.tussterm = "";
            dataService.value.sus = "";
            dataService.value.susprocedure = "";
            dataService.value.description = "";
        }

        onMounted(async () => {
            mounted.value = await true;
        });

        return {
            dataService,
            close,
            onUpdate,
            onSubmit,
            listSuggestionsTuss,
            callSuggestionTuss,
            listSuggestionsSus,
            round,
            disabled,
            isMedical,
            visibleTussSus,
            changeMedical,
            sugDescription,
            clearSusDescription,
            clearall,
            inPerson,
            videoCall,
            homeCare,
            msgErros,
            errorAllServiceTypes,
            isLoading
        };
    },
};
</script>
  
<style lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 1.5rem;
}
.inative{
    opacity: 0.6;
}
::v-deep(.p-dropdown) {
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}
</style>