<template>
  <div>
    <div class="surface-card p-5 mb-3" v-if="congratulationsMessage">
      <div class="service-content text-center">
        <i class="fas fa-medkit" style="font-size: 35px"></i>
        <h1>Parabéns.</h1>
      </div>
      <p class="text-center">Você adicionou seu primeiro serviço!</p>
      <p class="text-center">
        Agora você pode incluir um local de atendimento e vincular esse serviço!
      </p>
    </div>

    <div 
      v-for="service in myServicesList" 
      :key="service.id" 
      class="myServices-card" 
      :style="service.status === 'Inativo' && 'opacity: .5'"
      v-if="myServicesList.length > 0"
    >
      <div class="info">
        <div class="flex align-items-center gap-8">
          <img src="../../../assets/icons/myservices/bandage.png" alt="" />
          <h1 class="flex align-items-center" v-tooltip.top="'Detalhes de <i>' + service.name + '</i>'"
            @click="open('View', service.id)">
            {{ service.name }}
          </h1>
        </div>
        <p class="profession">
          <img src="../../../assets/icons/myservices/profission.png" alt="" />
          {{ service.specialitiesList[0].desccouncil }}
          -
          {{ service.specialitiesList[0].state }}
        </p>
        <div class="description">
          <img src="../../../assets/icons/myservices/tag.png" alt="" />
          <p>{{ service.description }}</p>
        </div>
      </div>

      <div class="flex h-max align-items-center gap-40">
        <!-- Presencial -->
        <div class="flex flex-column gap-4" style="min-width: 100px"
          :style="{ opacity: getStatus('Presencial', service.servicetypesList) ?? 0.5 }">
          <div class="flex align-items-center">
            <div class="tag-color" style="background: #a2c0d4" />
            <img class="ml-6 mr-4" src="../../../assets/icons/currency.png" alt="" />
            <span class="color-value">
              {{ valueIsNull(getValue("Presencial", service.servicetypesList)) }}
            </span>
          </div>
          <div class="flex align-items-center">
            <img class="clock-time" src="../../../assets/icons/clock-time.png" alt="" />
            <span class="color-date">
              {{ timeIsNull(getTime("Presencial", service.servicetypesList)) }} h
            </span>
          </div>
        </div>
        <!-- Videochamada -->
        <div class="flex flex-column gap-4" style="min-width: 100px"
          :style="{ opacity: getStatus('Videochamada', service.servicetypesList) ?? 0.5 }">
          <div class="flex align-items-center">
            <div class="tag-color" style="background: #2d313d" />
            <img class="ml-6 mr-4" src="../../../assets/icons/currency.png" alt="" />
            <span class="color-value">{{
                valueIsNull(getValue("Videochamada", service.servicetypesList))
            }}</span>
          </div>
          <div class="flex align-items-center">
            <img class="clock-time" src="../../../assets/icons/clock-time.png" alt="" />
            <span class="color-date">
              {{ timeIsNull(getTime("Videochamada", service.servicetypesList)) }} h
            </span>
          </div>
        </div>
        <!-- Homecare -->
        <div class="flex flex-column gap-4" style="min-width: 100px"
          :style="{ opacity: getStatus('Homecare', service.servicetypesList) ?? 0.5 }">
          <div class="flex align-items-center">
            <div class="tag-color" style="background: #ff4500" />
            <img class="ml-6 mr-4" src="../../../assets/icons/currency.png" alt="" />
            <span class="color-value">
              {{ valueIsNull(getValue("Homecare", service.servicetypesList)) }}
            </span>
          </div>
          <div class="flex align-items-center">
            <img class="clock-time" src="../../../assets/icons/clock-time.png" alt="" />
            <span class="color-date">
              {{ timeIsNull(getTime("Homecare", service.servicetypesList)) }} h
            </span>
          </div>
        </div>
      </div>

      <div class="flex align-items-center gap-26">
        <div class="flex cursor-pointer align-items-center gap-6 edit-data" v-tooltip.top="'Editar <i>' + service.name + '</i>'"
          @click="open('Edit', service.id)">
          Editar dados
          <img src="../../../assets/icons/edit-permission.png" />
        </div>

        <InputSwitch :trueValue="service.status === 'Ativo' ? false : true"
            @click="onChangeSwitch(service.status, service.id)" />
      </div>

    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  props: ["myServicesList", "onChangeSwitch", "dialogViewEditService", "filter", "congratulationsMessage"],
  emits: ["update:dialogViewEditService"],
  setup(props, ctx) {
    const currencyBrl = (value) => {
      const real = value.toLocaleString("pt-br", { style: "currency", currency: "BRL" });

      return real;
    };

    const open = (name, numberId) => {
      ctx.emit("update:dialogViewEditService", { status: true, type: name, id: numberId });
    };

    const valueIsNull = (value) => {
      if (value) {
        return value;
      }
      return currencyBrl(0);
    };

    const getValue = (type, arr) => {
      const item = arr.find((item) => item.servicemode === type);

      if (item) return currencyBrl(item.value);

      return null;
    };

    const minutesToHm = minutes => {
      var sec_num = parseInt(minutes); // don't forget the second param
      var hours = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);

      if (minutes < 10) { minutes = "0" + minutes; }
      if (seconds < 10) { seconds = "0" + seconds; }
      return minutes + ':' + seconds;
    }

    const getTime = (type, arr) => {
      const item = arr.find((item) => item.servicemode === type);

      if (item) return minutesToHm(item.estimatedTime);

      return null;
    };

    const timeIsNull = (value) => {
      if (value) {
        return value;
      }
      return "00:00";
    };

    const getStatus = (type, arr) => {
      const item = arr.find((item) => item.servicemode === type);

      if (item && item.status === "Ativo") return true;

      return null;
    };

    onMounted(() => {
      // console.log(props.myServicesList)
    })

    return {
      valueIsNull,
      getValue,
      getTime,
      timeIsNull,
      getStatus,
      open,
    };
  },
};
</script>

<style lang="scss" scoped>
.myServices-card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 16px;
  min-height: 120px;
  margin-bottom: 8px;
  box-shadow: 0px 8px 24px rgba(61, 71, 102, 0.08), 0px 3px 6px rgba(61, 71, 102, 0.06);

  .info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 315px;
    max-width: 315px;
    height: 100%;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      color: #4f4f4f;
      margin: 0;
      height: 18px;
      cursor: pointer;
    }

    .profession {
      display: flex;
      align-items: center;
      gap: 6px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #828282;
      margin-top: 8px;
    }

    .description {
      display: flex;
      align-items: center;
      gap: 6px;
      margin-top: 8px;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #828282;
        max-width: 52ch;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}

.tag-color {
  width: 4px;
  height: 8px;
  border-radius: 2px;
}

.color-date {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  margin-left: 4px;
}
.clock-time{
  width: 13px;
  height: 13px;
  margin-left: 10px;
}
.color-value {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #828282;
}
.edit-data{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #828282;
}
</style>
