<template>
  <div class="box-sus">
    <div :class="type === 'View' && 'cursor-not-allowed'">
      <AutoComplete
        field="name"
        v-model="inputSearchSus"
        :suggestions="listSearchSusItems"
        placeholder="Digite o Sus"
        @complete="completeSearchSus($event)"
        @item-select="selectedSearchSus($event)"
        @clear="clearSus"
        :disabled="type === 'View'"
      />
      <img
        class="clear-input" 
        :class="type === 'View' && 'cursor-not-allowed'"
        src="../../../../assets/icons/myservices/clear-input.png" 
        alt="" 
        v-tooltip.left="'Limpar SUS e Descrição.'"
        @click="clearSus"
        v-if="viewClear"
      />
    </div>
    <div
      class="box-sugestions"
      v-if="suggestionSus.length > 0 && !(sus && susprocedure)"
    >
      <div
        class="item"
        v-for="item in suggestionSus"
        :key="item.sus"
        @click="selectSusFromList(item)"
      >
        {{ item.sus }} - {{ item.susprocedure }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import {
  SearchSusItemsRequest,
  SuggestionDescriptionRequest,
} from "../../../core/grpc/generated/tuss_pb";
import tussService from "../../../core/services/tussService";

export default {
  props: [
    "nameService", 
    "tuss",
    "tussterm",
    "sus", 
    "susprocedure", 
    "suggestionSus",
    "description",
    "type"
  ],
  emits: ["update:sus", "update:susprocedure", "update:description"],
  setup(props, {emit}) {
    const mounted = ref(false);
    const inputSearchSus = ref();
    const listSearchSusItems = ref([]);

    const viewClear = computed(() => {
      if(props.sus && props.susprocedure){
        return true;
      }
      return false;
    })

    const nameServiceIsSelected = () => {
      if (props.nameService) {
        return true;
      }
      return false;
    };

    const completeSearchSus = (event) => {
      console.log(event.query);
      setTimeout(async () => {
        const req = new SearchSusItemsRequest();
        req.setKey(event.query);

        const res = await tussService.searchSusItems(req);

        if (res) {
          const arr = [];
          console.log(res.sussesList);
          res.sussesList.map((item) => {
            arr.push({ ...item, name: `${item.code} - ${item.susterminology}` });
          });
          listSearchSusItems.value = arr;
        }
      }, 250);
    };

    const selectedSearchSus = async (event) => {
      console.log(event);
      emit("update:sus", event.value.code)
      emit("update:susprocedure", event.value.susprocedure)
    };

    const selectSusFromList = async (item) => {
      console.log(item)
      const obj = { ...item, name: `${item.sus} - ${item.susprocedure}` };
      inputSearchSus.value = obj;

      emit("update:sus", item.sus)
      emit("update:susprocedure", item.susprocedure)
    };

    const clearSus = () => {
      if(props.type !== 'View') {
        inputSearchSus.value = ""
        emit("update:sus", "")
        emit("update:susprocedure", "")
        emit("update:description", "")
      }
    };

    watch(() => [props.tuss, props.tussterm],
    ([newT, newTT], _) => {
      if(mounted.value){
        if(!newT, !newTT){
          inputSearchSus.value = ""
        }
      }
    })
    
    watch(() => [props.sus, props.susprocedure],
    async ([newS, newSP], _) => {
      if(mounted.value) {
        if(newS && newSP && props.tuss && props.tussterm){
          const req = new SuggestionDescriptionRequest();
          req.setTuss(props.tuss)
          req.setSus(newS)
          const res = await tussService.suggestionDescription(req);
  
          if (res.success) {
            emit("update:description", JSON.parse(res.data)[0])
          }
        }
      }
    })

    onMounted(async () => {
      if(props.sus && props.susprocedure){
        inputSearchSus.value = `${props.sus} - ${props.susprocedure}`
      }      
      mounted.value = await true;
    })

    return {
      viewClear,
      inputSearchSus,
      listSearchSusItems,
      nameServiceIsSelected,
      completeSearchSus,
      selectedSearchSus,
      selectSusFromList,
      clearSus,
    };
  },
};
</script>

<style lang="scss" scoped>
.box-sus {
  position: relative;
  max-height: 300px;
  overflow-y: auto;
  .clear-input{
    background: white;
    position: absolute;
    right: 8px;
    top: 13px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .box-sugestions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    margin-top: 8px;
    padding-right: 10px;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e0e0e0;
      border-radius: 25px;
    }

    &::-webkit-scrollbar-track {
      margin: 10px;
    }
    .item {
      display: flex;
      align-items: center;
      padding: 10px 12px;
      width: 100%;
      height: max-content;
      color: #333333;
      background: white;
      border-radius: 8px;
      border: 1px solid #ced4da;
      cursor: pointer;
      &:hover {
        border-color: #ff4500;
      }
    }
  }
}
::v-deep(.p-autocomplete) {
  width: 100%;
}
::v-deep(.p-dropdown) {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}
::v-deep(.p-autocomplete-input) {
  border-top: 1px solid #F2F2F2;
  border-radius: 8px;
  vertical-align: middle;
  height: 45px;
  padding-right: 32px;
}
</style>
