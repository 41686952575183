<template>
  <div class="box-tuss">
    <div :class="type === 'View' && 'cursor-not-allowed'">
      <AutoComplete
        field="name"
        v-model="inputSearchTuss"
        :suggestions="listSearchTussItems"
        placeholder="Digite o TUSS"
        @complete="completeSearchTuss($event)"
        @item-select="selectedSearchTuss($event)"
        @clear="clearTuss"
        :disabled="type === 'View'"
        forceSelection
      />
      <img
        class="clear-input" 
        :class="type === 'View' && 'cursor-not-allowed'"
        src="../../../../assets/icons/myservices/clear-input.png" 
        alt="" 
        v-tooltip.left="'Limpar TUSS, SUS e Descrição.'"
        @click="clearTuss"
        v-if="viewClear"
      />
    </div>
    <div
      class="box-sugestions"
      v-if="suggestionTuss.length > 0 && !(tuss && tussterm)"
    >
      <div
        class="item"
        v-for="item in suggestionTuss"
        :key="item.tuss"
        @click="selectTussFromList(item)"
      >
        {{ item.tuss }} - {{ item.tussterm }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, computed } from "vue";
import {
  ListTussSuggestionsRequest,
  SearchTussItemsRequest,
} from "../../../core/grpc/generated/tuss_pb";
import profileService from "../../../core/services/professionalProfileService";
import tussService from "../../../core/services/tussService";

export default {
  props: [
    "nameService", 
    "tuss", 
    "tussterm", 
    "sus",
    "susprocedure",
    "description",
    "suggestionTuss", 
    "suggestionSus", 
    "type"
  ],
  emits: [
    "update:tuss", 
    "update:tussterm",
    "update:sus",
    "update:susprocedure",
    "update:description",
    "update:suggestionSus"
  ],
  setup(props, {emit}) {
    const mounted = ref(false);
    const inputSearchTuss = ref();
    const listSearchTussItems = ref([]);

    const viewClear = computed(() => {
      if(props.tuss && props.tussterm){
        return true;
      }
      return false;
    })

    const completeSearchTuss = (event) => {
      setTimeout(async () => {
        const req = new SearchTussItemsRequest();
        req.setKey(event.query);

        const res = await tussService.searchTussItems(req);

        if (res) {
          const arr = [];
          console.log(res.tussesList);
          res.tussesList.map((item) => {
            arr.push({ ...item, name: `${item.code} - ${item.tussterminology}` });
          });
          listSearchTussItems.value = arr;
        }
      }, 250);
    };

    const selectedSearchTuss = async (event) => {
      emit("update:tuss", event.value.code)
      emit("update:tussterm", event.value.tussterminology)
    };

    const selectTussFromList = async (item) => {
      const obj = { ...item, name: `${item.tuss} - ${item.tussterm}` };
      inputSearchTuss.value = obj;

      emit("update:tuss", item.tuss)
      emit("update:tussterm", item.tussterm)
    };

    const tussSugestionList = async (listSpecialty) => {
      const req = new ListTussSuggestionsRequest();
      req.setSpecialtyList(listSpecialty);
      const res = await tussService.listTussSuggestion(req);

      if (res.success) {
        console.log(res);
        tuss.value = res.correlationsList;
      }
    };

    const clearTuss = () => {
      if(props.type !== 'View'){
        emit("update:tuss", "")
        emit("update:tussterm", "")   
  
        if(props.description){
          emit("update:description", "")
        }
        if(props.sus){
          emit("update:sus", "")
        }
        if(props.susprocedure){
          emit("update:susprocedure", "")
        }
      }
    };

    

    onMounted(async() => {
      if(props.tuss && props.tussterm){
        inputSearchTuss.value = `${props.tuss} - ${props.tussterm}`
      }   
      mounted.value = await true;   
    })

    return {
      viewClear,
      inputSearchTuss,
      listSearchTussItems,
      completeSearchTuss,
      selectedSearchTuss,
      selectTussFromList,
      clearTuss,
    };
  },
};
</script>

<style lang="scss" scoped>
.box-tuss {
  position: relative;
  max-height: 300px;
  overflow-y: auto;
  .clear-input{
    background: white;
    position: absolute;
    right: 8px;
    top: 13px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .box-sugestions {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    margin-top: 8px;
    padding-right: 10px;
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e0e0e0;
      border-radius: 25px;
    }

    &::-webkit-scrollbar-track {
      margin: 10px;
    }
    .item {
      display: flex;
      align-items: center;
      padding: 10px 12px;
      width: 100%;
      height: max-content;
      color: #333333;
      background: white;
      border-radius: 8px;
      border: 1px solid #ced4da;
      cursor: pointer;
      &:hover {
        border-color: #ff4500;
      }
    }
  }
}
::v-deep(.p-autocomplete) {
  width: 100%;
}
::v-deep(.p-dropdown) {
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}
::v-deep(.p-autocomplete-input) {
  border-top: 1px solid #F2F2F2;
  border-radius: 8px;
  vertical-align: middle;
  height: 45px;
  padding-right: 32px;
}
</style>
