import { TussProtoServicePromiseClient } from '../grpc/generated/tuss_grpc_web_pb';

const apiUrl = process.env.VUE_APP_API_URL;
const svc = new TussProtoServicePromiseClient(apiUrl);

async function getItem(request) {
    const response = await svc.tussGetItem(request, {});

    return response.toObject();
}

async function searchItems(request) {
    const response = await svc.tussSearchItems(request, {});

    return response.toObject();
}

async function searchTussItems(request) {
    const response = await svc.searchTussItems(request, {});

    return response.toObject();
}

async function searchSusItems(request) {
    const response = await svc.searchSusItems(request, {});

    return response.toObject();
}

async function suggestionDescription(request) {
    const response = await svc.suggestionDescription(request, {});

    return response.toObject();
}

async function listTussSuggestion(request) {
    const response = await svc.listTussSuggestions(request, {});

    return response.toObject();
}

async function listSusCorrelatedToTuss(request) {
    const response = await svc.listSusCorrelatedToTuss(request, {});

    return response.toObject();
}

export default {
    getItem,
    searchItems,
    searchSusItems,
    searchTussItems,
    suggestionDescription,
    listTussSuggestion,
    listSusCorrelatedToTuss,
}