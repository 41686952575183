import { useMenu } from "../../../../store/menu"
import profileService from "../../../core/services/professionalProfileService";
import tussService from "../../../core/services/tussService";
import {
    ListActiveRegistriesRequest,
    AddServiceRequest,
    ListServiceIdRequest,
    UpdateServiceRequest,
    EmptyRequest
} from "../../../core/grpc/generated/professionalProfile_pb.js";
import {
    ListSusCorrelatedToTussRequest,
    ListTussSuggestionsRequest,
    SearchTussItemsRequest,
    SuggestionDescriptionRequest,
    SearchSusItemsRequest,
} from "../../../core/grpc/generated/tuss_pb";

const logout = () => {
    const menu = useMenu();
    window.localStorage.removeItem('token');
    menu.logout();
    console.log("Acesso não autorizado");
}

const listProfissions = async () => {
    try {
        const req = new ListActiveRegistriesRequest();
        const res = await profileService.listActiveRegistries(req);
        const { success } = res

        if (success) {
            return res.councilprofessionalsList;
        }

        return null;
    } catch (error) {
        if(error.message === "Acesso não autorizado"){
            logout();
        }
        return error;
    }
}

const specialtiesStructure = obj => {
    const list = [];
    const { councilid, specialityList, actuationList } = obj;

    actuationList.map(actuation => {
        list.push({ ...actuation, councilid: councilid });
    })

    specialityList.map(specialtyid => {
        if (!list.find(item => item.specialtyid === specialtyid)) {
            list.push({
                councilid: councilid,
                specialtyid: specialtyid,
                actuationid: "",
            })
        }
    })

    if (list.length === 0) {
        list.push({
            councilid: councilid,
            specialtyid: "",
            actuationid: "",
        })
    }

    return list;
}

const suggestionTuss = async (listNamesSpecialties) => {
    try {
        const req = new ListTussSuggestionsRequest();
        req.setSpecialtyList(listNamesSpecialties);
        const res = await tussService.listTussSuggestion(req);
        const { success } = res

        if (success) { return res.correlationsList; }

        return [];
    } catch (error) {
        return error;
    }
}

const searchTuss = async (name) => {
    try {
        const req = new SearchTussItemsRequest();
        req.setKey(name);
        const res = await tussService.searchTussItems(req);

        if (res) {
            const arr = [];
            
            res.tussesList.map((item) => {
                arr.push({ ...item, name: `${item.code} - ${item.tussterminology}` });
            });
            return arr;
        }
        return []
    } catch (error) {
        console.log(error)
    }
}

const suggestionSus = async (tuss) => {
    try {
        const req = new ListSusCorrelatedToTussRequest();
        req.setTusskey(tuss);
        const res = await tussService.listSusCorrelatedToTuss(req);

        if (res.success) {
            const arr = [];
            res.correlationsList.map(item => {
                arr.push({ ...item, name: `${item.sus} - ${item.susprocedure}` });
            });
            return arr;
        }
    } catch (error) {
        console.log(error)
    }
}

const searchSus = async (key) => {
    try {
        const req = new SearchSusItemsRequest();
        req.setKey(key);
        const res = await tussService.searchSusItems(req);

        if (res.success) {
            const arr = [];
            res.sussesList.map((item) => {
                arr.push({ ...item, name: `${item.code} - ${item.susterminology}` });
            });
            return arr;
        }
        return [];
    } catch (error) {
        console.log(error)
    }
}

const suggestionDescription = async ({ tuss, sus }) => {
    try {
        const req = new SuggestionDescriptionRequest();
        req.setTuss(tuss)
        req.setSus(sus)
        const res = await tussService.suggestionDescription(req);

        if (res.success) {
            const arrSuggestions = JSON.parse(res.data)
            return arrSuggestions[0]
        }
    } catch (error) {
        console.log(error)
    }
}

const addService = async (obj) => {
    try {
        const req = new AddServiceRequest();
        let arrServiceTypes = [];
        req.setName(obj.data.name);
        req.setTuss(obj.data.tuss);
        req.setTussterm(obj.data.tussterm);
        req.setSus(obj.data.sus);
        req.setSusprocedure(obj.data.susprocedure);
        req.setDescription(obj.data.description);

        obj.data.specialitiesList.forEach((element) => {
            console.log(element);
            var specialities = new proto.professionalProfileApi.ServiceHasSpecialities();
            if (element.councilid) { specialities.setCouncilid(element.councilid) }
            if (element.specialtyid) { specialities.setSpecialityid(element.specialtyid) }
            if (element.actuationid) { specialities.setActionactuationid(element.actuationid) }
            req.addServiceHasSpecialities(specialities);
        });

        if (obj.data.pb_return) {
            req.setReturn(obj.data.pb_return);
            req.setTimeReturn(obj.data.timeReturn);
        }

        if (obj.inPerson.active) {
            arrServiceTypes.push("Presencial");
            var serviceTypes = new proto.professionalProfileApi.ProfessionalServiceTypes();
            serviceTypes.setServicemode("Presencial");
            serviceTypes.setEstimatedTime(obj.inPerson.duration);
            serviceTypes.setValue(obj.inPerson.value);
            req.addProfessionalServiceTypes(serviceTypes);
        }

        if (obj.videoCall.active) {
            arrServiceTypes.push("Videochamada");
            var serviceTypes = new proto.professionalProfileApi.ProfessionalServiceTypes();
            serviceTypes.setServicemode("Videochamada");
            serviceTypes.setEstimatedTime(obj.videoCall.duration);
            serviceTypes.setValue(obj.videoCall.value);
            req.addProfessionalServiceTypes(serviceTypes);
        }

        if (obj.homeCare.active) {
            arrServiceTypes.push("Homecare");
            var serviceTypes = new proto.professionalProfileApi.ProfessionalServiceTypes();
            serviceTypes.setServicemode("Homecare");
            serviceTypes.setEstimatedTime(obj.homeCare.duration);
            serviceTypes.setValue(obj.homeCare.value);
            serviceTypes.setMaxDisplacement(obj.homeCare.raio);
            serviceTypes.setValuekm(obj.homeCare.km);
            req.addProfessionalServiceTypes(serviceTypes);
        }

        const res = await profileService.addService(req);

        if (res.success) {
            console.log(res);
            return res;
        } else {
            throw {res, arrServiceTypes};
        }
    } catch (error) {
        const { arrServiceTypes } = error;

        if (error.message == "Acesso não autorizado") logout();
        const data = JSON.parse(error.res.data)
        const msgErros = {
            name: null,
            professionalServiceTypes: null,
            description: null,
            Presencial: { duration: null, currency: null },
            Videochamada: { duration: null, currency: null },
            Homecare: { duration: null, currency: null, km: null },
        }

        for (const property in data) {
            const msg = data[property][0]
            switch (property) {
                case 'professionalServiceTypes[0].Value':
                    msgErros[arrServiceTypes[0]].currency = msg
                    break;
                case 'professionalServiceTypes[0].EstimatedTime':
                    msgErros[arrServiceTypes[0]].duration = msg
                    break;
                case 'professionalServiceTypes[0].MaxDisplacement':
                    msgErros[arrServiceTypes[0]].raio = msg
                    break;
                case 'professionalServiceTypes[1].Value':
                    msgErros[arrServiceTypes[1]].currency = msg
                    break;
                case 'professionalServiceTypes[1].EstimatedTime':
                    msgErros[arrServiceTypes[1]].duration = msg
                    break;
                case 'professionalServiceTypes[1].MaxDisplacement':
                    msgErros[arrServiceTypes[1]].raio = msg
                    break;
                case 'professionalServiceTypes[2].Value':
                    msgErros[arrServiceTypes[2]].currency = msg
                    break;
                case 'professionalServiceTypes[2].EstimatedTime':
                    msgErros[arrServiceTypes[2]].duration = msg
                    break;
                case 'professionalServiceTypes[2].MaxDisplacement':
                    msgErros[arrServiceTypes[2]].raio = msg
                    break;
                case 'professionalServiceTypes':
                    msgErros.professionalServiceTypes = msg
                    break;
                default:
                    msgErros[property] = msg
            }
        }

        return {
            success: false,
            data: msgErros,
        }
    }

};

const DisplayMessage = async () => {
    try {
        const req = new EmptyRequest();
        const res = await profileService.displayMessage(req);

        return res.success;
    } catch (error) {
        console.log(error);
    }
}

export {
    listProfissions,
    specialtiesStructure,
    suggestionTuss,
    searchTuss,
    searchSus,
    suggestionSus,
    suggestionDescription,
    addService,
    DisplayMessage,
}